import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import {Button, Typography} from "@material-ui/core";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../App";
import {kUsersCollection} from "../model/AuthUser";
import ActionsPanel from "./components/ActionsPanel";
import AppPaper from "./components/AppPaper";
import Form, {InputsData, InputType} from "./components/Form";
import ScreenContent from "./components/ScreenContent";
import ScreenHeader from "./components/ScreenHeader";
import {kDashboardRoute} from "./DashboardScreen";
import {useHistory} from "react-router-dom";

export const kLoginRoute = '/login';

/**
 * Component representing Login screen.
 */
function LoginScreen () {
    const appContext = useContext (AppContext);
    const {authUser} = appContext;

    const history = useHistory ();

    useEffect (() => {
        appContext.SetTitle ('Login');
    }, []);

    useEffect (() => {
        if (authUser) {
            history.push (kDashboardRoute);
        }
    }, [authUser]);

    const [errorMsg, setErrorMsg] = useState<string> ();

	const [inputs, setInputs] = useState<InputsData> ({
        username: {
            type: InputType.Text,
            label: 'Username',
            value: '',
        },
        password: {
            type: InputType.Password,
            label: 'Password',
            value: '',
        },
    });

	/**
     * SignIn the user using credentials againt Firebase Auth.
     */
	 const Login = async () => {
        setErrorMsg (undefined);

        try {
            const userCredentials = await firebase.auth ().signInWithEmailAndPassword (inputs.username.value, inputs.password.value);

            const userDoc = await firebase.firestore ().collection (kUsersCollection)
            .doc (userCredentials.user!.email!)
            .get ();

            const data = userDoc.data ();

            if (data && data.role === 'admin') {
                // login is handled centrally from App, here we only check to be able to show error message
            } else {
                setErrorMsg ('Incorrect credentials');
            }
        } catch (e) {
            console.error (e);

            setErrorMsg ('Incorrect credentials');
        }
    };

    const errorJsx = errorMsg ? (
        <Typography color="error" align="right" paragraph={true}>
            {errorMsg}
        </Typography>
    ) : (
        <></>
    );

	return (
		<ScreenContent maxWidth="sm" centered={true}>
            <AppPaper>
				<ScreenHeader title="Login to Infokanal"/>

				<Form id="login-form" inputs={inputs} SetInputs={(inputs: InputsData) => setInputs (inputs)}/>

                {errorJsx}

				<ActionsPanel rightAction={
                    <Button variant="contained" color="primary" onClick={Login}>Login</Button>
                }/>
            </AppPaper>
        </ScreenContent>
	);
}

export default LoginScreen;
