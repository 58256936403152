import { Button, makeStyles, Theme } from "@material-ui/core";
import { useContext, useState } from "react";
import { Upload } from "../../Api";
import { AppContext } from "../../App";
import AppModal from "./AppModal";
import Form, { InputsData, InputType } from "./Form";

export const useStyles = makeStyles ((theme: Theme) => ({
    action: {
        marginRight: theme.spacing (1),
    },
}));

/**
 * Render the component into html.
 */
function Modals () {
    const classes = useStyles ();

    const appContext = useContext (AppContext);

    const {modals} = appContext;

    const modalsJSX: JSX.Element [] = [];

    if (modals.confirm) {
        /**
         * Hide the modal.
         */
        const confirmNo = () => {
            const newModals = {...appContext.modals};
            newModals.confirm!.open = false;

            appContext.setModals (newModals);

            modals.confirm!.onConfirm (false);
        };

        /**
         * Hide the modal and execute confirm action.
         */
        const confirmYes = () => {
            const newModals = {...appContext.modals};
            newModals.confirm!.open = false;

            appContext.setModals (newModals);

            modals.confirm!.onConfirm (true);
        };

        modalsJSX.push ((
            <AppModal key="confirm-modal" open={modals.confirm.open} text={modals.confirm.text} actions={(
                <>
                    <Button key="action-no" className={classes.action} variant="contained" onClick={confirmNo}>No</Button>

                    <Button key="action-yes" variant="contained" color="primary" onClick={confirmYes}>Yes</Button>
                </>
            )}/>
        ));
    }

    if (modals.value) {
        /**
        * Hide the modal.
        */
       const valueCancel = () => {
           const newModals = {...appContext.modals};
           newModals.value!.open = false;

           appContext.setModals (newModals);
       };

        /**
         * Hide the modal and execute confirm action.
         */
        const valueConfirm = () => {
            const newModals = {...appContext.modals};
            newModals.value!.open = false;

            appContext.setModals (newModals);

            modals.value!.onConfirm (newModals.value!.value);
        };

        modalsJSX.push ((
            <AppModal key="value-modal" open={modals.value.open} value={modals.value.value} actions={(
                <>
                    <Button key="action-cancel" className={classes.action} variant="contained" onClick={valueCancel}>Cancel</Button>

                    <Button key="action-confirm" variant="contained" color="primary" onClick={valueConfirm}>Confirm</Button> 
                </>
            )}/>
        ));
    }

    if (modals.fileUpload) {
        /**
         * Hide the modal.
         */
        const FileUploadCancel = () => {
            appContext.setModals (prev => {
                return {
                    ...prev,
                    fileUpload: {
                        ...prev.fileUpload!,
                        open: false,
                    },
                };
            });

            modals.fileUpload!.onSubmit (false);
        };

        const inputs: InputsData = {
            file: {
                type: InputType.File,
                label: 'File',
                value: '',
                UploadFile: (files: FileList) => {
                    const formData = new FormData ();
	                formData.append ('file', files [0]);

                    return new Promise<string> ((resolve, reject) => {
                        Upload (
                            formData,
                            appContext,
                            result => {
                                if (result.result?.success) {
                                    modals.fileUpload!.onSubmit (true);

                                    resolve ('');
                                } else {
                                    modals.fileUpload!.onSubmit (false);
                                    
                                    reject ();
                                }
                            },
                            undefined,
                            '/api/user-verification/csv'
                        );
                    });
                },
                FilePublicUrl: (valud: string) => {
                    throw Error();
                },
                anyFile: true
            },
        };

        modalsJSX.push ((
            <AppModal key="fileUpload-modal" open={modals.fileUpload.open} text={modals.fileUpload.text} body={(
                <Form id="fileUpload-form" inputs={inputs} SetInputs={(inputs: InputsData) => {}}/>
            )} actions={(
                <Button key="action-cancel" className={classes.action} variant="contained" onClick={FileUploadCancel}>Cancel</Button>
            )}/>
        ));
    }

    return (
        <div id="Modals">
            {modalsJSX}
        </div>
    );
}

export default Modals;
