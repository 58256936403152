import {Button, IconButton, makeStyles, Table, TableBody, TableHead, TableRow, Theme} from "@material-ui/core";
import {Delete as IconDelete, Edit} from "@material-ui/icons";
import moment from "moment";
import {useContext, useEffect} from "react";
import {Delete, Get} from "../../Api";
import {AppContext} from "../../App";
import IResponseArticle from "../../model/ResponseArticle";
import {UpdateNonArchivedArticles} from "../../model/providers/ArticleProvider";
import ActionsPanel from "../components/ActionsPanel";
import AppPaper from "../components/AppPaper";
import ScreenContent from "../components/ScreenContent";
import AppTableCell from "../components/table/AppTableCell";
import {kArticleAddRoute, kArticleEditRoute} from "./ArticleEditScreen";
import {useHistory} from "react-router-dom";

export const kArticlesListRoute = '/article';

export const useStyles = makeStyles ((theme: Theme) => ({
	tableImage: {
		height: 48,
	},
}));

/**
 * Component representing Articles list screen.
 */
function ArticlesListScreen () {
    const appContext = useContext (AppContext);
	const {articles} = appContext;

	const history = useHistory ();

    useEffect (() => {
        appContext.SetTitle ('Articles');
    }, []);

	useEffect (() => {
		const cancelTokenSource = UpdateNonArchivedArticles (appContext);

		return () => {
			cancelTokenSource.cancel ();
		};
	}, []);

	const tableItems = articles.data && articles.data.length > 0 ? articles.data.map (article => {
		return (
			<ArticleTableRow key={article.id} article={article}/>
		);
	}) : (
		<TableRow>
			<AppTableCell colSpan={5} align="center">
                {articles.data ? 'No Articles found' : 'Loading...'}
            </AppTableCell>
		</TableRow>
	);

	return (
		<ScreenContent>
			<ActionsPanel marginBottom={true} rightAction={
                <Button variant="contained" color="primary" onClick={() => history.push (kArticleAddRoute)}>Add Article</Button>
            }/>

			<AppPaper>
				<Table stickyHeader={true}>
                    <TableHead>
                        <TableRow>
                            <AppTableCell>Title</AppTableCell>
                            <AppTableCell>Author</AppTableCell>
                            <AppTableCell>Featured Image</AppTableCell>
                            <AppTableCell>Created</AppTableCell>
                            <AppTableCell align="center"/>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {tableItems}
                    </TableBody>
                </Table>
			</AppPaper>
		</ScreenContent>
	);
}

interface IArticleTableRowProps {
	article: IResponseArticle;
}

/**
 * TableRow for Article component.
 */
function ArticleTableRow (props: IArticleTableRowProps) {
	const {article} = props;

    const appContext = useContext (AppContext);

    const history = useHistory ();

	const classes = useStyles ();

	const image = article.imageUrl ? (
        <a href={article.imageUrl} rel="noreferrer" target="_blank">
            <img className={classes.tableImage} src={article.imageUrl} alt={`${article.image}`}/>
        </a>
    ) : '-';

	let created = 'N/A';
	if (article.created) {
		const date = moment (article.created);

		created = date.format ('D.M.YYYY HH:mm');
	}

	/**
	 * Send Article set archived request to BE API and refresh list.
	 */
	const ArchiveArticle = () => {
		appContext.setModals ({
			confirm: {
				open: true,
				text: 'Do you want to archive this Article?',
				onConfirm: (confirmed: boolean) => {
					if (confirmed) {
						Get (
							`/api/article/archive/${article.id}`,
							appContext,
							result => {
								if (result.error) {
									console.error (result.error);
								}

								UpdateNonArchivedArticles (appContext);
							}
						)
					}
				},
			},
		});
	};

	/**
	 * Send delete request to BE API and refresh list.
	 */
	const DeleteArticle = () => {
		appContext.setModals ({
			confirm: {
				open: true,
				text: 'Do you want to delete this Article?',
				onConfirm: (confirmed: boolean) => {
					if (confirmed) {
						Delete (
							`/api/article/${article.id}`,
							appContext,
							result => {
								if (result.error) {
									console.error (result.error);
								}

								UpdateNonArchivedArticles (appContext);
							}
						)
					}
				},
			},
		});
	};

	return (
		<TableRow>
			<AppTableCell>{article.title}</AppTableCell>
			<AppTableCell>{article.author}</AppTableCell>
			<AppTableCell>{image}</AppTableCell>
			<AppTableCell>{created}</AppTableCell>
			<AppTableCell align="right">
				<Button onClick={() => ArchiveArticle ()}>Archive</Button>

				<IconButton onClick={() => DeleteArticle ()}><IconDelete/></IconButton>

				<IconButton onClick={() => history.push (kArticleEditRoute.replace (':articleId', `${article.id}`))}><Edit/></IconButton>
			</AppTableCell>
		</TableRow>
	);
}

export default ArticlesListScreen;
