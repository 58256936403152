import { Button, IconButton, Table, TableBody, TableHead, TableRow, Typography } from "@material-ui/core";
import {Delete as IconDelete, Edit } from "@material-ui/icons";
import moment from "moment";
import { useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Delete } from "../../Api";
import { AppContext } from "../../App";
import { UpdatePages } from "../../model/providers/PageProvider";
import IResponsePage from "../../model/ResponsePage";
import IResponsePageType from "../../model/ResponsePageType";
import { useStyles } from "../articles/ArticlesListScreen";
import ActionsPanel from "../components/ActionsPanel";
import AppPaper from "../components/AppPaper";
import ScreenContent from "../components/ScreenContent";
import AppTableCell from "../components/table/AppTableCell";
import { kPageAddRoute, kPageEditRoute } from "./PageEditScreen";

export const kPageListRoute = '/page-:pageTypeId';

export interface IParams {
    pageTypeId: string;
}

/**
 * Component for list of Pages by PageType screen.
 */
export default function PageListScreen () {
    const {pageTypeId} = useParams<IParams> ();

    const appContext = useContext (AppContext);
    const {pageTypes, pages} = appContext;

    const history = useHistory ();

    let pageType: IResponsePageType|undefined;

    if (pageTypes.data && pageTypes.data.length > 0) {
		for (const item of pageTypes.data) {
			if (`${item.id}` === `${pageTypeId}`) {
                pageType = item;
                break;
            }
		}
	}

    useEffect (() => {
        appContext.SetTitle (pageType ? pageType.pluralName : 'Page Type does not exist');
    }, [pageType]);

    useEffect (() => {
		if (pageType) {
            const cancelTokenSource = UpdatePages (appContext, pageTypeId);

            return () => {
                cancelTokenSource.cancel ();
            };
        }
	}, [pageType]);

    if (pageType) {
        const pagesByType = pages.data ? pages.data [pageTypeId] : undefined;

        const tableItems = pagesByType && pagesByType.length > 0 ? pagesByType.map (page => {
            return (
                <PageTableRow key={page.id} pageType={pageType!} page={page}/>
            );
        }) : (
            <TableRow>
                <AppTableCell colSpan={5} align="center">
                    {pagesByType ? `No ${pageType.pluralName} found` : 'Loading...'}
                </AppTableCell>
            </TableRow>
        );

        return (
            <ScreenContent>
                <ActionsPanel marginBottom={true} rightAction={
                    <Button variant="contained" color="primary" onClick={() => history.push (kPageAddRoute.replace (':pageTypeId', `${pageTypeId}`))}>Add {pageType.singularName}</Button>
                }/>
                
                <AppPaper>
                    <Table stickyHeader={true}>
                        <TableHead>
                            <TableRow>
                                <AppTableCell>Title</AppTableCell>
                                <AppTableCell>Featured Image</AppTableCell>
                                <AppTableCell>Created</AppTableCell>
                                <AppTableCell align="center"/>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {tableItems}
                        </TableBody>
                    </Table>
                </AppPaper>
            </ScreenContent>
        );
    } else {
        return (
            <ScreenContent>
                <AppPaper>
                    <Typography color="error">
                        Page Type does not exist
                    </Typography>
                </AppPaper>
            </ScreenContent>
        );
    }
}

interface IPageTableRowProps {
    pageType: IResponsePageType;
    page: IResponsePage;
}

/**
 * TableRow for Page by PageType component.
 */
function PageTableRow (props: IPageTableRowProps) {
    const {pageType, page} = props;

    const appContext = useContext (AppContext);

    const history = useHistory ();

	const classes = useStyles ();

	const image = page.imageUrl ? (
        <a href={page.imageUrl} rel="noreferrer" target="_blank">
            <img className={classes.tableImage} src={page.imageUrl} alt={`${page.image}`}/>
        </a>
    ) : '-';

	let created = 'N/A';
	if (page.created) {
		const date = moment (page.created);

		created = date.format ('D.M.YYYY HH:mm');
	}

	/**
	 * Send delete request to BE API and refresh list.
	 */
	const DeletePage = () => {
		appContext.setModals ({
			confirm: {
				open: true,
				text: `Do you want to delete this ${pageType.singularName}?`,
				onConfirm: (confirmed: boolean) => {
					if (confirmed) {
						Delete (
							`/api/page/${page.id}`,
							appContext,
							result => {
								if (result.error) {
									console.error (result.error);
								}

								UpdatePages (appContext, `${pageType.id}`);
							}
						)
					}
				},
			},
		});
	};

	return (
		<TableRow>
			<AppTableCell>{page.title}</AppTableCell>
			<AppTableCell>{image}</AppTableCell>
			<AppTableCell>{created}</AppTableCell>
			<AppTableCell align="right">
				<IconButton onClick={() => DeletePage ()}><IconDelete/></IconButton>

				<IconButton onClick={() => history.push (kPageEditRoute.replace (':pageTypeId', `${pageType.id}`).replace (':pageId', `${page.id}`))}><Edit/></IconButton>
			</AppTableCell>
		</TableRow>
	);
}
