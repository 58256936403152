import 'react-quill/dist/quill.snow.css';
import './EditorInput.css';

import { makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";
import { InputProps } from "./InputProps";
import ReactQuill from 'react-quill';

const useStyles = makeStyles ((theme: Theme) => ({
    label: {
		display: 'inline-block',
		paddingBottom: theme.spacing(0.5),
        marginLeft: 14,
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '1rem',
    },
}));

/**
 * Form editor input component.
 */
 function EditorInput (props: InputProps) {
	const {className, value, label, index, UpdateValue} = props;

	const classes = useStyles ();

	return (
		<div className={clsx ('Form_Editor', className)}>
			<span className={classes.label}>{label}</span>

			<ReactQuill value={value} onChange={value => UpdateValue (index, value)}/>
		</div>
	);
 }

 export default EditorInput;
