import { Drawer, List, ListItem, ListItemIcon, ListItemText, makeStyles, Theme, Toolbar } from "@material-ui/core";
import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { AppContext, IAppContext } from "../../App";
import IDrawerItem from "../../model/DrawerItem";
import { UpdatePageTypes } from "../../model/providers/PageTypeProvider";
import { kArticleAddRoute } from "../articles/ArticleEditScreen";
import { kArticlesListRoute } from "../articles/ArticlesListScreen";
import { kDashboardRoute } from "../DashboardScreen";
import { kFCMFormRoute } from "../FCMFormScreen";
import { kLogoutRoute } from "../LogoutScreen";
import { kPageListRoute } from "../pages/PageListScreen";
import { kPageTypesListRoute } from "../pageTypes/PageTypesListScreen";
import { kSettingsRoute } from "../SettingsScreen";
import {kVerificationCodeListRoute} from "../verificationCodes/VerificationCodesListScreen";
import {kVerificationCodeAddRoute} from "../verificationCodes/VerificationCodeEditScreen";
import {kConstructionSitesListRoute} from "../constructionSites/ConstructionSitesListScreen";

export const drawerWidth = 304;

const useStyles = makeStyles ((theme: Theme) => ({
	drawer: {
		width: drawerWidth
	},
	drawerSubmenu: {
		width: '100%',
		padding: 0
	},
}));

/**
 * Main Navigation Drawer component.
 */
function NavigationDrawer () {
	const appContext = useContext (AppContext);
	const {authUser} = appContext;

	const classes = useStyles ();

	useEffect (() => {
		if (authUser) {
			UpdatePageTypes (appContext);
		}
	}, [authUser]);

	if (authUser) {
		const drawerListItems = NavigationDrawerItems (appContext).map (item => {
			let isSelected = (item.exact && item.to === window.location.pathname) || (!item.exact && window.location.pathname.indexOf (item.to) === 0);
			if (!isSelected && item.children) {
				for (const child of item.children) {
					if ((child.exact && child.to === window.location.pathname) || (!child.exact && window.location.pathname.indexOf (child.to) === 0)) {
						isSelected = true;
						break;
					}
				}
			}

			let children = null;
			if (item.children && isSelected) {
				children = item.children.map (childElement => (
					<ListItem button={true} key={`${item.to}-${childElement.to}`} component={Link} to={childElement.to} selected={childElement.to === window.location.pathname}>
						<ListItemText secondary={childElement.text}/>
					</ListItem>
				));

				children = (
					<ul className={classes.drawerSubmenu}>
						{children}
					</ul>
				);
			}

			return (
				<li key={item.to}>
					<ListItem button={true} component={Link} to={item.to} selected={isSelected}>
						{item.icon ? <ListItemIcon>{item.icon}</ListItemIcon> : null}
						<ListItemText primary={item.text}/>
					</ListItem>

					{children}
				</li>
			);
		});

		return (
			<Drawer className={classes.drawer} variant="permanent">
				<Toolbar/>

				<List className={classes.drawer}>
					{drawerListItems}
				</List>
			</Drawer>
		);
	} else {
		return null;
	}
}

/**
 * Get list of items for navigation.
 */
function NavigationDrawerItems (appContext: IAppContext): IDrawerItem [] {
	const {pageTypes} = appContext;

	const items: IDrawerItem [] = [
		{
			text: 'Dashboard',
			to: kDashboardRoute,
			exact: true,
		},
		{
			text: 'Articles',
			to: kArticlesListRoute,
			children: [
				{
					text: 'Add Article',
					to: kArticleAddRoute
				}
			]
		},
		{
			text: 'Verification codes',
			to: kVerificationCodeListRoute,
			children: [
				{
					text: 'Add verification code',
					to: kVerificationCodeAddRoute
				}
			]
		},
		{
			text: 'Construction sites',
			to: kConstructionSitesListRoute,
		}
	];

	if (pageTypes.data && pageTypes.data.length > 0) {
		for (const pageType of pageTypes.data) {
			items.push ({
				text: pageType.pluralName,
				to: kPageListRoute.replace (':pageTypeId', `${pageType.id}`),
			});
		}
	}

	items.push (
		{
			text: 'Push Notifications',
			to: kFCMFormRoute,
		},
		{
			text: 'Settings',
			to: kSettingsRoute,
			children: [
				{
					text: 'Page Types',
					to: kPageTypesListRoute
				}
			]
		},
		{
			text: 'Logout',
			to: kLogoutRoute,
		},
	);

	return items;
}

export default NavigationDrawer;
