import { Typography } from "@material-ui/core";
import { useContext, useEffect } from "react";
import { AppContext } from "../App";
import AppPaper from "./components/AppPaper";
import ScreenContent from "./components/ScreenContent";
import ScreenHeader from "./components/ScreenHeader";

/**
 * Render the component into html.
 */
function NoMatchScreen () {
    const appContext = useContext (AppContext);

    useEffect (() => {
        appContext.SetTitle ('NoMatch');
    }, []);

    return (
        <ScreenContent centered={true} maxWidth="md">
            <ScreenHeader title="That page cannot be found"/>

            <AppPaper>
                <Typography>
                    It looks like the page you are looking for no longer exists or you are using incorrect url address.
                </Typography>
            </AppPaper>
        </ScreenContent>
    );
}

export default NoMatchScreen;
