import {CancelTokenSource} from "axios";
import {Get, IApiResponse} from "../../Api";
import {IAppContext} from "../../App";

/**
 * Load Construction ste from BE API.
 */
export function UpdateConstructionSites(appContext: IAppContext): CancelTokenSource {
    const {setConstructionSites} = appContext;

    return Get(
        '/api/construction-site',
        appContext,
        (result: IApiResponse) => {
            setConstructionSites(prev => {
                const newObject = {...prev};

                if (result.error) {
                    console.error (result.error);

                    newObject.data = [];
                } else {
                    newObject.data = result.result.constructionSiteList;
                }

                return newObject;
            });
        },
    );
}
