import { makeStyles, TableCell, TableCellProps, Theme } from "@material-ui/core";

export const useStyles = makeStyles ((theme: Theme) => ({
	tableCell: {
		padding: theme.spacing (1)
	},
}));

export interface IProps extends TableCellProps {

}

/**
 * Component for TableCell with App theme.
 */
function AppTableCell (props: IProps) {
	const classes = useStyles ();

	return (
		<TableCell {...props} className={classes.tableCell}>
			{props.children}
		</TableCell>
	);
}

export default AppTableCell;
