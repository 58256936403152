import { Button, Typography } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import { useContext, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Get, Post } from "../../Api";
import { AppContext } from "../../App";
import { UploadFile, FilePublicUrl } from "../../model/providers/FileProvider";
import IRequestPage from "../../model/RequestPage";
import IResponsePage from "../../model/ResponsePage";
import IResponsePageType from "../../model/ResponsePageType";
import ActionsPanel from "../components/ActionsPanel";
import AppPaper from "../components/AppPaper";
import Form, { InputsData, InputType, ValidateForm } from "../components/Form";
import ScreenContent from "../components/ScreenContent";
import { kPageListRoute } from "./PageListScreen";

export const kPageAddRoute = '/page-:pageTypeId/add';
export const kPageEditRoute = '/page-:pageTypeId/:pageId/edit';

export interface IParams {
    pageTypeId: string;
    pageId?: string;
}

/**
 * Component for Page add and edit screens.
 */
export default function PageEditScreen () {
	const {pageTypeId, pageId} = useParams<IParams> ();

    const appContext = useContext (AppContext);
    const {pageTypes} = appContext;

    const history = useHistory ();
    
    let pageType: IResponsePageType|undefined;

    if (pageTypes.data && pageTypes.data.length > 0) {
		for (const item of pageTypes.data) {
			if (`${item.id}` === `${pageTypeId}`) {
                pageType = item;
                break;
            }
		}
	}

	const [existingPage, setExistingPage] = useState<IResponsePage> ();
    const [errorMsg, setErrorMsg] = useState<string> ();
    const [success, setSuccess] = useState<boolean> ();

    useEffect (() => {
        appContext.SetTitle (pageType ? (pageId ? `Edit ${pageType.singularName}` : `Add ${pageType.singularName}`) : 'Page Type does not exist');
    }, [pageType]);

	const [inputs, setInputs] = useState<InputsData> ({
        title: {
            type: InputType.Text,
            label: 'Title',
            value: '',
			required: true,
        },
		image: {
			type: InputType.File,
            label: 'Image',
            value: '',
            UploadFile: (files: FileList) => UploadFile (appContext, files, 'page'),
            FilePublicUrl: (value: string) => FilePublicUrl (appContext, value),
		},
		content: {
			type: InputType.Editor,
			label: 'Content',
			value: ''
		},
		files: {
			type: InputType.File,
            label: 'Files',
            value: '',
            UploadFile: (files: FileList) => UploadFile (appContext, files, 'page'),
            FilePublicUrl: (value: string) => FilePublicUrl (appContext, value),
			multiple: true,
			anyFile: true,
		}
    });

	useEffect (() => {
		if (pageId) {
			const cancelTokenSource = Get (
				`/api/page/${pageId}`,
				appContext,
				result => {
					if (!result.error) {
						const existing = result.result as IResponsePage;

						let newInputs = {...inputs};

						newInputs.title.value = existing.title;
						newInputs.image.value = existing.image || '';
						newInputs.content.value = existing.content;
						newInputs.files.value = existing.files ? existing.files!.map(value => value.id) : '';

						setInputs (newInputs);

						setExistingPage (existing);
					}
				}
			);

			return () => {
				cancelTokenSource.cancel ();
			};
		}
	}, []);

    if (pageType) {
        const listRoute = kPageListRoute.replace (':pageTypeId', `${pageTypeId}`);

        /**
         * Save Page by PageType to BE API.
         */
        const Save = () => {
            setErrorMsg (undefined);
            setSuccess (undefined);
    
            if (ValidateForm (inputs, (inputs: InputsData) => setInputs (inputs))) {
                let tempFiles: number [] = [];
                if(inputs.files.value){
                    if(Array.isArray(inputs.files.value)) {
                        tempFiles = [...inputs.files.value];
                    } else {
                        tempFiles = inputs.files.value.split (',');
                    }
                }
    
                const page: IRequestPage = {
                    id: existingPage ? existingPage.id : undefined,
                    pageType: existingPage ? existingPage.pageType : parseInt (pageTypeId),
                    title: inputs.title.value,
                    image: inputs.image.value,
                    content: inputs.content.value,
                    files: tempFiles.length ? tempFiles : undefined,
                    created: existingPage ? existingPage.created : undefined
                };
    
                try {
                    Post (
                        '/api/page',
                        page,
                        appContext,
                        result => {
                            if (result.result?.success) {
                                setErrorMsg (`${pageType!.singularName} saved`);
                                setSuccess (true);
    
                                setTimeout (() => {
                                    history.push (listRoute);
                                }, 1000);
                            } else {
                                setErrorMsg ('API error');
                            }
                        }
                    );
                } catch (e) {
                    console.error (e);
    
                    setErrorMsg ('UnKnown Error');
                }
            }
        };

        const errorJsx = errorMsg ? (
            <Typography color={success ? 'primary' : 'error'} align="right" paragraph={true}>
                {errorMsg}
            </Typography>
        ) : (
            <></>
        );

        const content = pageId && !existingPage ? (
            <Typography color="error">
                {pageType.singularName} does not exist
            </Typography>
        ) : (
            <>
                <Form id="page-form" inputs={inputs} SetInputs={(inputs: InputsData) => setInputs (inputs)}/>
    
                {errorJsx}
    
                <ActionsPanel rightAction={
                    <Button variant="contained" color="primary" onClick={() => Save ()}>{pageId ? 'Update' : 'Add'}</Button>
                }/>
            </>
        );

        return (
            <ScreenContent>
                <ActionsPanel marginBottom={true} leftAction={
                    <Button variant="outlined" color="secondary" component={Link} to={listRoute}><ChevronLeft/></Button>
                }/>

                <AppPaper>
                    {content}
                </AppPaper>
            </ScreenContent>
        );
    } else {
        return (
            <ScreenContent>
                <AppPaper>
                    <Typography color="error">
                        Page Type does not exist
                    </Typography>
                </AppPaper>
            </ScreenContent>
        );
    }
}
