import {Button, IconButton, Table, TableBody, TableHead, TableRow} from "@material-ui/core";
import {ChevronLeft, Delete as IconDelete, Edit} from "@material-ui/icons";
import moment from "moment";
import {useContext, useEffect} from "react";
import {Link, useHistory} from "react-router-dom";
import {Delete} from "../../Api";
import {AppContext} from "../../App";
import ActionsPanel from "../components/ActionsPanel";
import AppPaper from "../components/AppPaper";
import ScreenContent from "../components/ScreenContent";
import AppTableCell from "../components/table/AppTableCell";
import {kSettingsRoute} from "../SettingsScreen";
import {UpdateUserVerifications} from "../../model/providers/UserVerificationProvider";
import IResponseUserVerification from "../../model/ResponseUserVerification";
import {kVerificationCodeAddRoute, kVerificationCodeEditRoute} from "./VerificationCodeEditScreen";

export const kVerificationCodeListRoute = '/settings/verification-code';

/**
 * Component for list of VerificationCodes screen.
 */
export default function VerificationCodesListScreen () {
    const appContext = useContext (AppContext);
    const {userVerifications} = appContext;

    const history = useHistory ();

    useEffect (() => {
        appContext.SetTitle ('Verification codes');
    }, []);

    useEffect (() => {
        const cancelTokenSource = UpdateUserVerifications (appContext);

        return () => {
            cancelTokenSource.cancel ();
        };
    }, []);

    const tableItems = userVerifications.data && userVerifications.data.length > 0 ? userVerifications.data.map (userVerification => {
        return (
            <UserVerificationTableRow key={userVerification.id} userVerification={userVerification}/>
        );
    }) : (
        <TableRow>
            <AppTableCell colSpan={5} align="center">
                {userVerifications.data ? 'No verification codes found' : 'Loading...'}
            </AppTableCell>
        </TableRow>
    );

    return (
        <ScreenContent>
            <ActionsPanel marginBottom={true} leftAction={
                <Button variant="outlined" color="secondary" component={Link} to={kSettingsRoute}><ChevronLeft/></Button>
            } rightAction={
                <Button variant="contained" color="primary" onClick={() => history.push (kVerificationCodeAddRoute)}>Add Verification code</Button>
            }/>

            <AppPaper>
                <Table stickyHeader={true}>
                    <TableHead>
                        <TableRow>
                            <AppTableCell>Username (optional)</AppTableCell>
                            <AppTableCell>Verification code</AppTableCell>
                            <AppTableCell>Created</AppTableCell>
                            <AppTableCell>Email</AppTableCell>
                            <AppTableCell>Verified</AppTableCell>
                            <AppTableCell align="center"/>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {tableItems}
                    </TableBody>
                </Table>
            </AppPaper>
        </ScreenContent>
    );
}

interface IVerificationCodeTableRowProps {
    userVerification: IResponseUserVerification;
}

/**
 * TableRow for UserVerification component.
 */
function UserVerificationTableRow (props: IVerificationCodeTableRowProps) {
    const {userVerification} = props;

    const appContext = useContext (AppContext);

    const history = useHistory ();

    /**
     * Send delete request to BE API and refresh list.
     */
    const DeleteVerificationCode = () => {
        appContext.setModals ({
            confirm: {
                open: true,
                text: 'Do you want to delete this Verification code?',
                onConfirm: (confirmed: boolean) => {
                    if (confirmed) {
                        Delete (
                            `/api/user-verification/${userVerification.id}`,
                            appContext,
                            result => {
                                if (result.error) {
                                    console.error (result.error);
                                }

                                UpdateUserVerifications (appContext);
                            }
                        )
                    }
                },
            },
        });
    };

    const date = moment (userVerification.createdAt);
    const createdAt = date.format ('D.M.YYYY HH:mm');

    const verifiedDate = moment (userVerification.verifiedAt);
    const verifiedAt = verifiedDate.format ('D.M.YYYY HH:mm');

    return (
        <TableRow>
            <AppTableCell>{userVerification.username}</AppTableCell>
            <AppTableCell>{userVerification.code}</AppTableCell>
            <AppTableCell>{createdAt}</AppTableCell>
            <AppTableCell>{userVerification.email}</AppTableCell>
            <AppTableCell>{verifiedAt}</AppTableCell>
            <AppTableCell align="right">
                <IconButton onClick={() => DeleteVerificationCode ()}><IconDelete/></IconButton>

                <IconButton onClick={() => history.push (kVerificationCodeEditRoute.replace (':verificationCodeId', `${userVerification.id}`))}><Edit/></IconButton>
            </AppTableCell>
        </TableRow>
    );
}
