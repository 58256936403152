import { useContext } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { AppContext } from "../../App";

export interface Props extends RouteProps {
    redirectTo: string;
}

/**
 * Route which requires authorized user.
 */
function ProtectedRoute (props: Props) {
    const {component, redirectTo, ...rest} = props;
    const appContext = useContext (AppContext);
    const {authUser} = appContext;
    
    if (authUser) {
        return (
            <Route {...rest} component={component}/>
        );
    } else {
        return (
            <Redirect to={redirectTo}/>
        );
    }
}

export default ProtectedRoute;
