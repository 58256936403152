export const kUsersCollection = "users";

interface IAuthUser {
    email: string;
    emailVerified: boolean;
    uid: string;
    idToken: string;
}

export default IAuthUser;
