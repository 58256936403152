import './App.css';

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import React, {createContext, Dispatch, SetStateAction, useEffect, useState} from 'react';
import {BrowserRouter} from 'react-router-dom';
import IFlavor from './model/Flavor';
import {FlavorByDomain} from './config';
import {createTheme, makeStyles, Theme, ThemeProvider} from '@material-ui/core/styles';
import Cog from './icons/Cog';
import {AppBar, CssBaseline, Toolbar, Typography} from '@material-ui/core';
import clsx from 'clsx';
import Router from './Router';
import IAuthUser, {kUsersCollection} from './model/AuthUser';
import NavigationDrawer from './ui/components/NavigationDrawer';
import IResponseArticle from './model/ResponseArticle';
import Modals from './ui/components/Modals';
import IResponsePageType from './model/ResponsePageType';
import IResponsePage from './model/ResponsePage';
import IResponseUserVerification from './model/ResponseUserVerification';
import IResponseConstructionSite from "./model/ResponseConstructionSite";

export const AppContext = createContext<IAppContext> ({} as IAppContext);

export interface IAppContext {
	SetTitle: (title?: string) => void;
	flavor?: IFlavor;
	authUser?: IAuthUser;
	SetAuthUser: (authUser?: IAuthUser) => void;
	modals: IAppContextModals;
	setModals: React.Dispatch<React.SetStateAction<IAppContextModals>>;
	articles: IAppContextArticles;
	SetArticles: (articles: IAppContextArticles) => void;
	pageTypes: IAppContextPageTypes;
	setPageTypes: React.Dispatch<React.SetStateAction<IAppContextPageTypes>>;
	pages: IAppContextPages;
	setPages: React.Dispatch<React.SetStateAction<IAppContextPages>>;
	userVerifications: IAppContextUserVerifications;
	setUserVerifications: React.Dispatch<React.SetStateAction<IAppContextUserVerifications>>;
	constructionSites: IAppContextConstructionSites;
	setConstructionSites: Dispatch<SetStateAction<IAppContextConstructionSites>>;
}

export interface IAppContextModals {
	confirm?: {
		open: boolean;
		text: string;
		onConfirm: (confirmed: boolean) => void;
	};
	value?: {
		open: boolean;
		value: string;
		onConfirm: (value: string) => void;
	};
	fileUpload?: {
		open: boolean;
		text: string;
		onSubmit: (success: boolean) => void;
	};
}

export interface IAppContextArticles {
	data?: IResponseArticle [];
}

export interface IAppContextConstructionSites {
	data?: IResponseConstructionSite[];
}

export interface IAppContextPageTypes {
	data?: IResponsePageType [];
}

export interface IAppContextPages {
	data?: {
		[k: string]: IResponsePage [];
	};
}

export interface IAppContextUserVerifications {
	data?: IResponseUserVerification [];
}

const title = document.getElementById ('document-title');
const appTitle = title && title.dataset.title ? title.dataset.title : '';

const theme = createTheme ({
	palette: {
		primary: {
			main: '#2e7d32',
			light: '#60ad5e',
			dark: '#005005'
		},
		secondary: {
			main: '#212121',
			light: '#484848',
			dark: '#000000'
		}
	}
});

export const appUseStyles = makeStyles ((theme: Theme) => ({
	appBar: {
		zIndex: theme.zIndex.drawer + 1
	},
	displayFlex: {
		display: "flex"
	},
	flexGrow: {
		flexGrow: 1
	},
	icon: {
		width: 48,
		height: 48,
	},
	smallIcon: {
		width: 24,
		height: 24,
	},
}));

/**
 * Component representing the App.
 */
function App () {
    const classes = appUseStyles ();

	const [title, setTitle] = useState<string> ();

	useEffect (() => {
		document.title = title ? `${title} - ${appTitle}` : appTitle;
	}, [title]);

	const [appReady, setAppReady] = useState<boolean> ();
	const [flavor, setFlavor] = useState<IFlavor> ();
	const [authUser, setAuthUser] = useState<IAuthUser> ();
	const [authUserVerified, setAuthUserVerified] = useState<boolean> (false);
	const [modals, setModals] = useState<IAppContextModals> ({});
	const [articles, setArticles] = useState<IAppContextArticles> ({});
	const [pageTypes, setPageTypes] = useState<IAppContextPageTypes> ({});
	const [pages, setPages] = useState<IAppContextPages> ({});
	const [userVerifications, setUserVerifications] = useState<IAppContextUserVerifications> ({});
	const [constructionSites, setConstructionSites] = useState<IAppContextConstructionSites>({});

	useEffect (() => {
		InitApp (setAppReady, setFlavor);
	}, []);

	useEffect (() => {
		if (appReady) {
			const unsubscribe = firebase.auth ().onAuthStateChanged (async user => {
				if (user) {
					try {
						const idToken = await user.getIdToken ();

						const userDoc = await firebase.firestore ().collection (kUsersCollection)
						.doc (user.email!)
						.get ();

						const data = userDoc.data ();

						if (data && data.role === 'admin') {
							const authUser: IAuthUser = {
								email: user.email!,
								emailVerified: user.emailVerified,
								uid: user.uid,
								idToken,
							};

							setAuthUser (authUser);
						} else {
							setAuthUser (undefined);
						}
					} catch (e) {
						console.error (e);

						setAuthUser (undefined);
					}
				} else {
					setAuthUser (undefined);
				}

				setAuthUserVerified (true);
			});

			return () => {
				unsubscribe ();
			};
		}
	}, [appReady]);

	const appContext = {
		SetTitle: (title?: string) => setTitle (title),
		flavor,
		authUser,
		SetAuthUser: (authUser?: IAuthUser) => setAuthUser (authUser),
		modals,
		setModals,
		articles,
		SetArticles: (articles: IAppContextArticles) => setArticles (articles),
		pageTypes, setPageTypes,
		pages, setPages,
		userVerifications, setUserVerifications,
		constructionSites, setConstructionSites,
	};

	const appClasses = ['App'];

	const appBarJsx = authUser ? (
		<AppBar className={classes.appBar} position="fixed">
			<Toolbar>
				<Typography component="h1">
					{title || appTitle}
				</Typography>
			</Toolbar>
		</AppBar>
	) : null;

	const content = appReady && authUserVerified ? (
		<>
			{appBarJsx}

			<BrowserRouter>
				<NavigationDrawer/>

				<main id="content">
					<Router/>
				</main>
			</BrowserRouter>
		</>
	) : (
		<Cog spin={true}/>
	);

	if (!(appReady && authUserVerified)) {
		appClasses.push ('center-content');
	}

	return (
		<AppContext.Provider value={appContext}>
			<ThemeProvider theme={theme}>
				<div id="app" className={clsx (appClasses)}>
					<CssBaseline/>

					{content}

					<Modals/>
				</div>
			</ThemeProvider>
		</AppContext.Provider>
	);
}

/**
 * Initialize App before first content render.
 * Find current Flavor.
 * Init Firebase by Flavor.
 */
function InitApp (
	setAppReady: (appReady: boolean) => void,
	setFlavor: (flavor: IFlavor) => void
	) {
	const flavor = FlavorByDomain ();

	if (flavor) {
		firebase.initializeApp (flavor.firebase);

		setFlavor (flavor);
	}

	setAppReady (true);
}

export default App;
