import { Button, Typography } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import { useContext, useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Get, Post } from "../../Api";
import { AppContext } from "../../App";
import IRequestPageType from "../../model/RequestPageType";
import IResponsePageType from "../../model/ResponsePageType";
import ActionsPanel from "../components/ActionsPanel";
import AppPaper from "../components/AppPaper";
import Form, { InputsData, InputType, ValidateForm } from "../components/Form";
import ScreenContent from "../components/ScreenContent";
import { kPageTypesListRoute } from "./PageTypesListScreen";

export const kPageTypeAddRoute = '/settings/page-type/add';
export const kPageTypeEditRoute = '/settings/page-type/:pageTypeId/edit';

export interface IParams {
    pageTypeId?: string;
}

/**
 * Component for PageType add and edit screen.
 */
export default function PageTypeEditScreen () {
    const {pageTypeId} = useParams<IParams> ();
    
    const appContext = useContext (AppContext);

    const history = useHistory ();

	const [existingPageType, setExistingPageType] = useState<IResponsePageType> ();
	const [errorMsg, setErrorMsg] = useState<string> ();
    const [success, setSuccess] = useState<boolean> ();

    useEffect (() => {
        appContext.SetTitle (pageTypeId ? 'Edit Page Type' : 'Add Page Type');
    }, []);

    const [inputs, setInputs] = useState<InputsData> ({
		title: {
            type: InputType.Text,
            label: 'Title',
            value: '',
			required: true,
        },
        singularName: {
            type: InputType.Text,
            label: 'Singular Name',
            value: '',
			required: true,
        },
        pluralName: {
            type: InputType.Text,
            label: 'Plural Name',
            value: '',
			required: true,
        },
	});

	useEffect (() => {
		if (pageTypeId) {
			const cancelTokenSource = Get (
				`/api/page-type/${pageTypeId}`,
				appContext,
				result => {
					if (!result.error) {
						const existing = result.result as IResponsePageType;

						let newInputs = {...inputs};

						newInputs.title.value = existing.title;
                        newInputs.singularName.value = existing.singularName;
                        newInputs.pluralName.value = existing.pluralName;

						setInputs (newInputs);

						setExistingPageType (existing);
					}
				},
			);

			return () => {
				cancelTokenSource.cancel ();
			};
		}
	}, []);

	/**
     * Save PageType to BE API.
     */
	const Save = () => {
		setErrorMsg (undefined);
		setSuccess (undefined);

		if (ValidateForm (inputs, (inputs: InputsData) => setInputs (inputs))) {
			const pageType: IRequestPageType = {
				id: existingPageType ? existingPageType.id : undefined,
				title: inputs.title.value,
				singularName: inputs.singularName.value,
                pluralName: inputs.pluralName.value,
				deleted: existingPageType ? existingPageType.deleted : undefined,
				created: existingPageType ? existingPageType.created : undefined
			};

			try {
				Post (
					'/api/page-type',
					pageType,
					appContext,
					result => {
						if (result.result?.success) {
							setErrorMsg ('Page Type saved');
							setSuccess (true);

							setTimeout (() => {
								history.push (kPageTypesListRoute);
							}, 1000);
						} else {
							setErrorMsg ('API error');
						}
					}
				);
			} catch (e) {
				console.error (e);

				setErrorMsg ('UnKnown Error');
			}
		}
	};

	const errorJsx = errorMsg ? (
        <Typography color={success ? 'primary' : 'error'} align="right" paragraph={true}>
            {errorMsg}
        </Typography>
    ) : (
        <></>
    );

	const content = pageTypeId && !existingPageType ? (
		<Typography color="error">
			Page Type does not exist
		</Typography>
	) : (
		<>
			<Form id="page-type-form" inputs={inputs} SetInputs={(inputs: InputsData) => setInputs (inputs)}/>

			{errorJsx}

			<ActionsPanel rightAction={
				<Button variant="contained" color="primary" onClick={() => Save ()}>{pageTypeId ? 'Update' : 'Add'}</Button>
			}/>
		</>
	);

	return (
		<ScreenContent>
			<ActionsPanel marginBottom={true} leftAction={
				<Button variant="outlined" color="secondary" component={Link} to={kPageTypesListRoute}><ChevronLeft/></Button>
			}/>
			
			<AppPaper>
				{content}
			</AppPaper>
		</ScreenContent>
	);
}
