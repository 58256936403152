import { CancelTokenSource } from "axios";
import { Get, IApiResponse } from "../../Api";
import { IAppContext } from "../../App";

/**
 * Load Pages from BE API.
 */
export function UpdatePages (appContext: IAppContext, pageTypeId: string): CancelTokenSource {
    return Get (
		`/api/page?pageType=${pageTypeId}`,
		appContext,
		(result: IApiResponse) => {
			const newPages = {...appContext.pages};

			if (!newPages.data) {
				newPages.data = {};
			}

			if (result.error) {
				console.error (result.error);

				newPages.data[pageTypeId] = [];
			} else {
				newPages.data[pageTypeId] = result.result.pageList;
			}

			appContext.setPages (newPages);
		},
	);
}
