import { Grid, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import clsx from "clsx";
import { appUseStyles } from "../../App";

const useStyles = makeStyles ((theme: Theme) => ({
    screenHeader: {
        padding: theme.spacing (1),
		marginBottom: theme.spacing (2),
        background: theme.palette.primary.main,
        color: 'white',
        textAlign: 'center',
    },
}));

export interface Props {
    title: string;
    action?: JSX.Element;
}

/**
 * Screen Header component with App theme.
 */
function ScreenHeader (props: Props) {
    const {title, action} = props;

    const classes = useStyles ();
    const appClasses = appUseStyles ();

    const renderAction = action ? (
        <Grid item={true}>
            {action}
        </Grid>
    ) : null;

    return (
        <Paper className={clsx ('ScreenHeader', classes.screenHeader)}>
            <Grid container={true} spacing={2}>
                <Grid className={appClasses.flexGrow} item={true}>
                    <Typography variant="h4">
                        {title}
                    </Typography>

                    {renderAction}
                </Grid>
            </Grid>
        </Paper>
    );
}

export default ScreenHeader;
