import { kDevA, kDevB, kSchleithde } from "./firebase-config";
import IFlavor from "./model/Flavor";

export interface IConfig {
	flavors: IFlavor [];
}

export const kConfig: IConfig = {
	flavors: [
		{
			name: "a",
			title: "Infokanal A",
			domains: [
				"localhost:3000",
			],
			firebase: kDevA,
			api: "http://127.0.0.1:8082"
		},
		{
			name: "a",
			title: "Infokanal A",
			domains: [
				"manage-infokanal-a.appliable.eu"
			],
			firebase: kDevA,
			api: "https://infokanal.appliable.eu"
		},
		{
			name: "b",
			title: "Infokanal B",
			domains: [
				"localhost:3001",
			],
			firebase: kDevB,
			api: "http://127.0.0.1:8082"
		},
		{
			name: "b",
			title: "Infokanal B",
			domains: [
				"manage-infokanal-b.appliable.eu"
			],
			firebase: kDevB,
			api: "https://infokanal.appliable.eu"
		},
		{
			name: "schleithde",
			title: "Infokanal Schleithde",
			domains: [
				"localhost:3002"
			],
			firebase: kSchleithde,
			api: "http://127.0.0.1:8082"
		},
		{
			name: "schleithde",
			title: "Infokanal Schleithde",
			domains: [
				"admin.schleith.de"
			],
			firebase: kSchleithde,
			api: "https://api.schleith.de"
		}
	]
};

/**
 * Get Flavor for current domain if possible.
 */
export function FlavorByDomain (): IFlavor|null {
	const host = window.location.host;

	for (const flavor of kConfig.flavors) {
		if (flavor.domains.includes (host)) {
			return flavor;
		}
	}

	return null;
}
