import {makeStyles, Table, TableBody, TableContainer, TableHead, TableRow, Theme} from "@material-ui/core";
import {useContext, useEffect} from "react";
import {AppContext} from "../../App";
import ScreenContent from "../components/ScreenContent";
import {UpdateConstructionSites} from "../../model/providers/ConstructionSiteProvider";
import IResponseConstructionSite from "../../model/ResponseConstructionSite";
import AppTableCell from "../components/table/AppTableCell";
import AppPaper from "../components/AppPaper";

export const kConstructionSitesListRoute = '/construction-site';

export const useStyles = makeStyles ((theme: Theme) => ({
    tableImage: {
        height: 48,
    },
}));

/**
 * Screen component to display list of Construction sites.
 */
export default function ConstructionSitesListScreen() {
    const appContext = useContext(AppContext);
    const {SetTitle, constructionSites} = appContext;

    useEffect(() => {
        SetTitle('Construction sites');
    }, []);

    useEffect (() => {
        const cancelTokenSource = UpdateConstructionSites(appContext);

        return () => {
            cancelTokenSource.cancel ();
        };
    }, []);

    const tableItems = constructionSites.data && constructionSites.data.length > 0 ? constructionSites.data.map (constructionSite => {
        return (
            <ConstructionSiteTableRow key={constructionSite.id} constructionSite={constructionSite}/>
        );
    }) : (
        <TableRow>
            <AppTableCell colSpan={7} align="center">
                {constructionSites.data ? 'No Construction sites found' : 'Loading...'}
            </AppTableCell>
        </TableRow>
    );

    return (
        <ScreenContent>
            <AppPaper>
                <TableContainer>
                    <Table stickyHeader={true}>
                        <TableHead>
                            <TableRow>
                                <AppTableCell>Name</AppTableCell>
                                <AppTableCell>Position</AppTableCell>
                                <AppTableCell>Site Location</AppTableCell>
                                <AppTableCell>Area/Service</AppTableCell>
                                <AppTableCell>Image</AppTableCell>
                                <AppTableCell>Description</AppTableCell>
                                <AppTableCell align="center"/>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {tableItems}
                        </TableBody>
                    </Table>
                </TableContainer>
            </AppPaper>
        </ScreenContent>
    );
}

interface IConstructionSiteTableRowProps {
    constructionSite: IResponseConstructionSite;
}

/**
 * TableRow component for Construction site.
 */
function ConstructionSiteTableRow(props: IConstructionSiteTableRowProps) {
    const {constructionSite} = props;

    const classes = useStyles();

    const image = constructionSite.imageUrl ? (
        <a href={constructionSite.imageUrl} rel="noreferrer" target="_blank">
            <img className={classes.tableImage} src={constructionSite.imageUrl} alt={`${constructionSite.image}`}/>
        </a>
    ) : '-';

    return (
        <TableRow>
            <AppTableCell>{constructionSite.firstName} {constructionSite.lastName}</AppTableCell>

            <AppTableCell>{constructionSite.position}</AppTableCell>

            <AppTableCell>{constructionSite.siteLocation}</AppTableCell>

            <AppTableCell>{constructionSite.areaService}</AppTableCell>

            <AppTableCell>{image}</AppTableCell>

            <AppTableCell>{constructionSite.description}</AppTableCell>

            <AppTableCell align="center"/>
        </TableRow>
    );
}
