import { CancelTokenSource } from "axios";
import { Get, IApiResponse } from "../../Api";
import { IAppContext } from "../../App";

/**
 * Load Articles from BE API that are not archived.
 */
export function UpdateNonArchivedArticles (appContext: IAppContext): CancelTokenSource {
	return Get (
		'/api/article',
		appContext,
		(result: IApiResponse) => {
			const newArticles = {...appContext.articles};

			if (result.error) {
				console.error (result.error);

				newArticles.data = [];
			} else {
				newArticles.data = result.result.articleList;
			}

			appContext.SetArticles (newArticles);
		}
	);
}
