import { makeStyles, Paper, Theme } from "@material-ui/core";
import { type } from "os";

export const useStyles = makeStyles ((theme: Theme) => ({
	paper: {
		padding: theme.spacing (1),
		marginBottom: theme.spacing (2)
	},
}));

type ElementOrNull = JSX.Element | null;

export interface Props {
	children?: ElementOrNull | ElementOrNull [];
}

/**
 * Paper component with App theme.
 */
function AppPaper (props: Props) {
	const {children} = props;

	const classes = useStyles ();

	return (
		<Paper className={classes.paper}>
			{children}
		</Paper>
	);
}

export default AppPaper;
