import { Container, makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";
import AppBarSpacer from "./AppBarSpacer";
import { drawerWidth } from "./NavigationDrawer";

const useStyles = makeStyles ((theme: Theme) => ({
    screenContent: {
        paddingTop: theme.spacing (2),
    },
    screenContentWrapper: {
        paddingLeft: drawerWidth,
    }
}));

export interface Props {
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
    children?: JSX.Element | JSX.Element [] | null;
    centered?: boolean;
}

/**
 * Screen component with App theme.
 */
function ScreenContent (props: Props) {
    const {maxWidth, children, centered} = props;

    const classes = useStyles ();

    return (
        <div className={clsx (centered ? null : classes.screenContentWrapper)}>
            <AppBarSpacer/>

            <Container className={classes.screenContent} maxWidth={maxWidth}>
                {children || ''}
            </Container>
        </div>
    );
}

export default ScreenContent;
