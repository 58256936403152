import {Button, Typography} from "@material-ui/core";
import {ChevronLeft} from "@material-ui/icons";
import {useContext, useEffect, useState} from "react";
import {Link, useHistory, useParams} from "react-router-dom";
import {Get, Post} from "../../Api";
import {AppContext} from "../../App";
import IResponseArticle from "../../model/ResponseArticle";
import {FilePublicUrl, UploadFile} from "../../model/providers/FileProvider";
import ActionsPanel from "../components/ActionsPanel";
import AppPaper from "../components/AppPaper";
import Form, {InputsData, InputType, ValidateForm} from "../components/Form";
import ScreenContent from "../components/ScreenContent";
import {kArticlesListRoute} from "./ArticlesListScreen";
import IRequestArticle from "../../model/RequestArticle";

export const kArticleAddRoute = '/article/add';
export const kArticleEditRoute = '/article/:articleId/edit';

export interface IParams {
    articleId?: string;
}

/**
 * Component for Article add and edit screens.
 */
function ArticleEditScreen () {
	const {articleId} = useParams<IParams> ();

    const appContext = useContext (AppContext);

    const history = useHistory ();

	const [existingArticle, setExistingArticle] = useState<IResponseArticle> ();
    const [errorMsg, setErrorMsg] = useState<string> ();
    const [success, setSuccess] = useState<boolean> ();

    useEffect (() => {
        appContext.SetTitle (articleId ? 'Edit Article' : 'Add Article');
    }, []);

	const [inputs, setInputs] = useState<InputsData> ({
        title: {
            type: InputType.Text,
            label: 'Title',
            value: '',
			required: true,
        },
		author: {
			type: InputType.Text,
            label: 'Author',
            value: '',
			required: true,
		},
		image: {
			type: InputType.File,
            label: 'Image',
            value: '',
            UploadFile: (files: FileList) => UploadFile (appContext, files, 'article'),
            FilePublicUrl: (value: string) => FilePublicUrl (appContext, value),
		},
		content: {
			type: InputType.Editor,
			label: 'Content',
			value: ''
		},
		youtube: {
			type: InputType.Text,
			label: 'Youtube Video',
			value: '',
			requireUri: true,
		},
		gallery: {
			type: InputType.File,
            label: 'Gallery',
            value: '',
            UploadFile: (files: FileList) => UploadFile (appContext, files, 'article'),
            FilePublicUrl: (value: string) => FilePublicUrl (appContext, value),
			multiple: true,
		},
		files: {
			type: InputType.File,
            label: 'Files',
            value: '',
            UploadFile: (files: FileList) => UploadFile (appContext, files, 'article'),
            FilePublicUrl: (value: string) => FilePublicUrl (appContext, value),
			multiple: true,
			anyFile: true,
		}
    });

	useEffect (() => {
		if (articleId) {
			const cancelTokenSource = Get (
				`/api/article/${articleId}`,
				appContext,
				result => {
					if (!result.error) {
						const existing = result.result as IResponseArticle;

						let newInputs = {...inputs};

						newInputs.title.value = existing.title;
						newInputs.author.value = existing.author;
						newInputs.image.value = existing.image || '';
						newInputs.content.value = existing.content;
						newInputs.youtube.value = existing.youtube || '';
						newInputs.gallery.value = existing.gallery ? existing.gallery.join (',') : '';
						newInputs.files.value = existing.files ? existing.files!.map(value => value.id) : '';

						setInputs (newInputs);

						setExistingArticle (existing);
					}
				}
			);

			return () => {
				cancelTokenSource.cancel ();
			};
		}
	}, []);

	/**
     * Save Article to BE API.
     */
	const Save = () => {
		setErrorMsg (undefined);
		setSuccess (undefined);

		if (ValidateForm (inputs, (inputs: InputsData) => setInputs (inputs))) {
			let tempFiles: number [] = [];
			if(inputs.files.value){
				if(Array.isArray(inputs.files.value)) {
					tempFiles = [...inputs.files.value];
				} else {
					tempFiles = inputs.files.value.split (',');
				}
			}

			const article: IRequestArticle = {
				id: existingArticle ? existingArticle.id : undefined,
				title: inputs.title.value,
				author: inputs.author.value,
				image: inputs.image.value,
				content: inputs.content.value,
				youtube: inputs.youtube.value,
				gallery: inputs.gallery.value ? inputs.gallery.value.split (',') : undefined,
				files: tempFiles.length ? tempFiles : undefined,
				created: existingArticle ? existingArticle.created : undefined
			};

			try {
				Post (
					'/api/article',
					article,
					appContext,
					result => {
						if (result.result?.success) {
							setErrorMsg ('Article saved');
							setSuccess (true);

							setTimeout (() => {
								history.push (kArticlesListRoute);
							}, 1000);
						} else {
							setErrorMsg ('API error');
						}
					}
				);
			} catch (e) {
				console.error (e);

				setErrorMsg ('UnKnown Error');
			}
		}
	};

	const errorJsx = errorMsg ? (
        <Typography color={success ? 'primary' : 'error'} align="right" paragraph={true}>
            {errorMsg}
        </Typography>
    ) : (
        <></>
    );

	const content = articleId && !existingArticle ? (
		<Typography color="error">
			Article does not exist
		</Typography>
	) : (
		<>
			<Form id="article-form" inputs={inputs} SetInputs={(inputs: InputsData) => setInputs (inputs)}/>

			{errorJsx}

			<ActionsPanel rightAction={
				<Button variant="contained" color="primary" onClick={() => Save ()}>{articleId ? 'Update' : 'Add'}</Button>
			}/>
		</>
	);

	return (
		<ScreenContent>
			<ActionsPanel marginBottom={true} leftAction={
                <Button variant="outlined" color="secondary" component={Link} to={kArticlesListRoute}><ChevronLeft/></Button>
            }/>

			<AppPaper>
				{content}
			</AppPaper>
		</ScreenContent>
	);
}

export default ArticleEditScreen;
