import { Get, Upload } from "../../Api";
import { IAppContext } from "../../App";
import {FileInputFile} from "../../ui/components/form/InputProps";

/**
 * Upload file to BE API.
 */
export function UploadFile (appContext: IAppContext, files: FileList, category: string, isImage: boolean = true): Promise<string> {
	const formData = new FormData ();
	formData.append ('file', files [0]);

	if (category) {
		formData.append ('category', category);
	}

	return new Promise<string> ((resolve, reject) => {
		Upload (
			formData,
			appContext,
			result => {
				if (result.result?.success) {
					resolve (`${result.result.id}`);
				} else {
					reject ();
				}
			}
		);
	});
}

/**
 * Get file public URL from BE API.
 */
export async function FilePublicUrl (appContext: IAppContext, value: string): Promise<FileInputFile> {
	return new Promise<FileInputFile> ((resolve, reject) => {
		Get (
			`/api/file/info/${value}`,
			appContext,
			result => {
				if (result.result) {
					resolve (result.result);
				} else {
					reject ();
				}
			}
		);
	});
}
