import { Button, Typography } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { Post } from "../Api";
import { AppContext } from "../App";
import { IFcmMessage } from "../model/Fcm";
import { UpdateNonArchivedArticles } from "../model/providers/ArticleProvider";
import ActionsPanel from "./components/ActionsPanel";
import AppPaper from "./components/AppPaper";
import Form, { InputsData, InputType, ValidateForm } from "./components/Form";
import ScreenContent from "./components/ScreenContent";

export const kFCMFormRoute = '/fcm';

/**
 * Component representing screen with form for FCM.
 */
function FCMFormScreen () {
	const appContext = useContext (AppContext);
	// const {articles} = appContext;

    const [errorMsg, setErrorMsg] = useState<string> ();
    const [success, setSuccess] = useState<boolean> ();

    useEffect (() => {
        appContext.SetTitle ('Push Notifications');
    }, []);

    useEffect (() => {
		const cancelTokenSource = UpdateNonArchivedArticles (appContext);

		return () => {
			cancelTokenSource.cancel ();
		};
	}, []);
	
	const [inputs, setInputs] = useState<InputsData> ({
        title: {
            type: InputType.Text,
            label: 'Title',
            value: '',
            required: true,
        },
        body: {
            type: InputType.Text,
            label: 'Body',
            value: '',
            required: true,
        },
        // article: {
        //     type: InputType.Select,
        //     label: 'Article',
        //     value: '',
        //     options: [{label: 'None', value: ''}]
        // }
    });

    // useEffect (() => {
    //     if (articles.data) {
    //         const newInputs = {...inputs};

    //         const articleOptions = [
    //             {label: 'None', value: ''}
    //         ];
            
    //         for (const article of articles.data) {
    //             articleOptions.push ({
    //                 label: article.title,
    //                 value: `${article.id}`
    //             });
    //         }

    //         newInputs.article.options = articleOptions;

    //         setInputs (newInputs);
    //     }
    // }, [articles]);

    /**
     * Send FCM message to BE API.
     */
    const Send = () => {
        setErrorMsg (undefined);
		setSuccess (undefined);

        if (ValidateForm (inputs, setInputs)) {
            const message: IFcmMessage = {
                title: inputs.title.value,
                body: inputs.body.value,
                // article: inputs.article.value ? parseInt (inputs.article.value) : undefined
            };

            try {
                Post (
                    '/api/fcm/message',
                    message,
                    appContext,
                    result => {
                        if (result.result?.success) {
                            setErrorMsg ('Push Notifications message sent');
							setSuccess (true);

                            const newInputs = {...inputs};
                            newInputs.title.value = '';
                            newInputs.body.value = '';
                            // newInputs.article.value = '';
                            setInputs (newInputs);
                        } else {
							setErrorMsg ('API error');
						}
                    }
                );
            } catch (e) {
                console.error (e);

				setErrorMsg ('UnKnown Error');
            }
        }
    };

    const errorJsx = errorMsg ? (
        <Typography color={success ? 'primary' : 'error'} align="right" paragraph={true}>
            {errorMsg}
        </Typography>
    ) : (
        <></>
    );

	return (
		<ScreenContent>
            <Typography paragraph={true}>
                Send push message to all registered devices. Keep it short, ideally less than 40 characters.
            </Typography>

			<AppPaper>
				<Form id="article-form" inputs={inputs} SetInputs={(inputs: InputsData) => setInputs (inputs)}/>

                {errorJsx}

                <ActionsPanel rightAction={
                    <Button variant="contained" color="primary" onClick={() => Send ()}>Send</Button>
                }/>
			</AppPaper>
		</ScreenContent>
	);
}

export default FCMFormScreen;
