/* eslint-disable no-whitespace-before-property */
import { makeStyles, Theme } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import clsx from "clsx";
import { validate as isEmail } from "email-validator";
import { isUri } from "valid-url";
import FileInput from "./form/FileInput";
import {
    CheckBoxProps,
    DatePickerProps,
    FileInputFile,
    FileInputProps,
    InputProps,
    Option,
    SelectProps,
    TextAreaProps
} from "./form/InputProps";
import PasswordInput from "./form/PasswordInput";
import Select from "./form/Select";
import TextArea from "./form/TextArea";
import TextInput from "./form/TextInput";
import DatePicker from "./form/DatePicker";
import CheckBox from "./form/CheckBox";
import Chips from "./form/Chips";
import EditorInput from "./form/EditorInput";

export enum InputType {
    None,
    Text,
	Password,
    TextArea,
    File,
    Select,
    CustomRenderer,
    DatePicker,
    CheckBox,
    Chips,
    Editor,
}

export interface InputsData {
    [index: string]: {
        type: InputType;
        label: string;
        value: any;
        hidden?: boolean;
        rows?: string;
        error?: boolean;
		errorMessage?: string;
        required?: boolean;
        requiredMessage?: string;
        requireUri?: boolean;
        requireUriMessage?: string;
        requireEmail?: boolean;
        requireEmailMessage?: string;
        uploading?: boolean;
        UploadFile?: (files: FileList) => Promise<string>;
        FilePublicUrl?: (value: string) => Promise<FileInputFile>;
        multiple?: boolean;
        anyFile?: boolean;
        options?: Option [];
	    OnChange?: (index: string, value: string) => void;
        Render?: () => JSX.Element;
    };
}

export const formUseStyles = makeStyles ((theme: Theme) => ({
    hidden: {
        display: 'none',
    },
}));

export interface Props {
    id: string;
    className?: string;
    inputs: InputsData;
    SetInputs: (inputs: InputsData) => void;
}

/**
 * Render the component into html.
 */
function Form (props: Props) {
    const {id, className, inputs, SetInputs} = props;

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <div className={clsx ('Form', className)}>
                {RenderInputs (inputs, id, SetInputs)}
            </div>
        </MuiPickersUtilsProvider>
    );
}

/**
 * Render inputs based on data.
 */
function RenderInputs (inputs: InputsData, id: string, SetInputs: (inputs: InputsData) => void): JSX.Element [] {
    const children: JSX.Element [] = [];

    /**
     * Update fields prop value.
     */
    const UpdateProp = (index: string, key: string, value: any) => {
        const newObject = {...inputs};

        // newObject [index] [key] = value;
        const theField = newObject [index] as any;

        theField [key] = value;

        SetInputs (newObject);
    };

    /**
     * Update fields value inside inputs data.
     */
    const UpdateValue = (index: string, value: any) => {
        const newObject = {...inputs};

        newObject [index].value = value;

        SetInputs (newObject);
    };

    for (let index in inputs) {
        if (inputs.hasOwnProperty (index)) {
            const input = inputs [index];

            switch (input.type) {
                case InputType.Text: {
                    const inputProps: InputProps = input as any;

                    children.push (<TextInput key={`${id}-${index}`} id={`${id}-${index}`} {...inputProps} index={index} UpdateProp={UpdateProp} UpdateValue={UpdateValue}/>);
                    break;
                }
                case InputType.Password: {
                    const inputProps: InputProps = input as any;

                    children.push (<PasswordInput key={`${id}-${index}`} id={`${id}-${index}`} {...inputProps} index={index} UpdateProp={UpdateProp} UpdateValue={UpdateValue}/>);
                    break;
                }
                case InputType.TextArea: {
                    if (!input.rows) {
                        throw Error ('Not valid TextAreaProps');
                    }

                    const textAreaProps: TextAreaProps = input as any;

                    children.push (<TextArea key={`${id}-${index}`} id={`${id}-${index}`} {...textAreaProps} index={index} UpdateProp={UpdateProp} UpdateValue={UpdateValue}/>);
                    break;
                }
                case InputType.File: {
                    if (!input.UploadFile || !input.FilePublicUrl) {
                        throw Error ('Not valid FileInputProps');
                    }

                    const inputProps: FileInputProps = input as any;

                    children.push (<FileInput key={`${id}-${index}`} id={`${id}-${index}`} {...inputProps} index={index} UpdateProp={UpdateProp} UpdateValue={UpdateValue}/>);
                    break;
                }
                case InputType.Select: {
                    if (!input.options) {
                        throw Error ('Not valid SelectProps');
                    }

                    const selectProps: SelectProps = input as any;

                    children.push (<Select key={`${id}-${index}`} id={`${id}-${index}`} {...selectProps} index={index} UpdateProp={UpdateProp} UpdateValue={UpdateValue}/>);
                    break;
                }
                case InputType.CustomRenderer: {
                    if (!input.Render) {
                        throw Error ('Not valid CustomRenderer');
                    }

                    children.push (<div key={`${id}-${index}`}>{input.Render ()}</div>);
                    break;
                }
                case InputType.DatePicker: {
                    if (typeof input.value !== 'number' && typeof input.value !== 'undefined') {
                        throw Error ('Not valid DatePickerProps');
                    }

                    const datePickerProps: DatePickerProps = input as any;

                    children.push (<DatePicker key={`${id}-${index}`} id={`${id}-${index}`} {...datePickerProps} index={index} UpdateProp={UpdateProp} UpdateValue={UpdateValue}/>);
                    break;
                }
                case InputType.CheckBox: {
                    if (typeof input.value !== 'boolean') {
                        throw Error ('Not valid CheckBoxProps');
                    }

                    const checkBoxProps: CheckBoxProps = input as any;

                    children.push (<CheckBox key={`${id}-${index}`} id={`${id}-${index}`} {...checkBoxProps} index={index} UpdateProp={UpdateProp} UpdateValue={UpdateValue}/>);
                    break;
                }
                case InputType.Chips: {
                    const inputProps: InputProps = input as any;

                    children.push (<Chips key={`${id}-${index}`} id={`${id}-${index}`} {...inputProps} index={index} UpdateProp={UpdateProp} UpdateValue={UpdateValue}/>);
                    break;
                }
                case InputType.Editor: {
                    const inputProps: InputProps = input as any;

                    children.push (<EditorInput key={`${id}-${index}`} id={`${id}-${index}`} {...inputProps} index={index} UpdateProp={UpdateProp} UpdateValue={UpdateValue}/>);
                    break;
                }
                default:
                    throw Error ('Unsupported form input type');
            }
        }
    }

    return children;
}

/**
 * Validate fields of form.
 */
export function ValidateForm (inputs: InputsData, setInputs: (inputs: InputsData) => void): boolean {
    let valid = true;

    for (let index in inputs) {
        if (inputs.hasOwnProperty (index)) {
            const input = inputs [index];

            input.error = false;

            if (input.required) {
                if (!input.value || input.value.length === 0) {
                    input.error = true;
                    input.errorMessage = input.requiredMessage || `${input.label} is required field.`;
                    valid = false;
                }
            }

            if (!input.error && input.requireUri) {
                if (input.value.length > 0 && !isUri (input.value)) {
                    input.error = true;
                    input.errorMessage = input.requireUriMessage || `${input.label} is not valid url.`;
                    valid = false;
                }
            }

            if (!input.error && input.requireEmail) {
                if (input.value.length > 0 && !isEmail (input.value)) {
                    input.error = true;
                    input.errorMessage = input.requireEmailMessage || `${input.label} is not valid email address.`;
                    valid = false;
                }
            }

            if (!input.error && input.type === InputType.File) {
                if (input.uploading) {
                    input.error = true;
                    input.errorMessage = `${input.label} has not finished uploading.`;
                    valid = false;
                }
            }
        }
    }

    const newObject = {...inputs};

    setInputs (newObject);

    return valid;
}

export default Form;
