import { Button, IconButton, Table, TableBody, TableHead, TableRow } from "@material-ui/core";
import {Delete as IconDelete, ChevronLeft, Edit } from "@material-ui/icons";
import moment from "moment";
import { useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Delete } from "../../Api";
import { AppContext } from "../../App";
import { UpdatePageTypes } from "../../model/providers/PageTypeProvider";
import IResponsePageType from "../../model/ResponsePageType";
import ActionsPanel from "../components/ActionsPanel";
import AppPaper from "../components/AppPaper";
import ScreenContent from "../components/ScreenContent";
import AppTableCell from "../components/table/AppTableCell";
import { kSettingsRoute } from "../SettingsScreen";
import { kPageTypeAddRoute, kPageTypeEditRoute } from "./PageTypeEditScreen";

export const kPageTypesListRoute = '/settings/page-type';

/**
 * Component for list of PageTypes screen.
 */
export default function PageTypesListScreen () {
    const appContext = useContext (AppContext);
	const {pageTypes} = appContext;

	const history = useHistory ();

    useEffect (() => {
        appContext.SetTitle ('Page Types');
    }, []);

	useEffect (() => {
		const cancelTokenSource = UpdatePageTypes (appContext);

		return () => {
			cancelTokenSource.cancel ();
		};
	}, []);

	const tableItems = pageTypes.data && pageTypes.data.length > 0 ? pageTypes.data.map (pageType => {
		return (
			<PageTypeTableRow key={pageType.id} pageType={pageType}/>
		);
	}) : (
		<TableRow>
			<AppTableCell colSpan={5} align="center">
                {pageTypes.data ? 'No Page Types found' : 'Loading...'}
            </AppTableCell>
		</TableRow>
	);

    return (
        <ScreenContent>
            <ActionsPanel marginBottom={true} leftAction={
                <Button variant="outlined" color="secondary" component={Link} to={kSettingsRoute}><ChevronLeft/></Button>
            } rightAction={
                <Button variant="contained" color="primary" onClick={() => history.push (kPageTypeAddRoute)}>Add Page Type</Button>
            }/>
            
            <AppPaper>
				<Table stickyHeader={true}>
					<TableHead>
						<TableRow>
							<AppTableCell>Title</AppTableCell>
                            <AppTableCell>Singular Name</AppTableCell>
                            <AppTableCell>Plural Name</AppTableCell>
                            <AppTableCell>Created</AppTableCell>
                            <AppTableCell align="center"/>
						</TableRow>
					</TableHead>

					<TableBody>
						{tableItems}
					</TableBody>
				</Table>
			</AppPaper>
        </ScreenContent>
    );
}

interface IPageTypeTableRowProps {
    pageType: IResponsePageType;
}

/**
 * TableRow for PageType component.
 */
function PageTypeTableRow (props: IPageTypeTableRowProps) {
    const {pageType} = props;

    const appContext = useContext (AppContext);

    const history = useHistory ();

	/**
	 * Send delete request to BE API and refresh list.
	 */
	const DeletePageType = () => {
		appContext.setModals ({
			confirm: {
				open: true,
				text: 'Do you want to delete this Page Type?',
				onConfirm: (confirmed: boolean) => {
					if (confirmed) {
						Delete (
							`/api/page-type/${pageType.id}`,
							appContext,
							result => {
								if (result.error) {
									console.error (result.error);
								}

								UpdatePageTypes (appContext);
							}
						)
					}
				},
			},
		});
	};

	const date = moment (pageType.created);
	const created = date.format ('D.M.YYYY HH:mm');

	return (
		<TableRow>
			<AppTableCell>{pageType.title}</AppTableCell>
			<AppTableCell>{pageType.singularName}</AppTableCell>
			<AppTableCell>{pageType.pluralName}</AppTableCell>
			<AppTableCell>{created}</AppTableCell>
			<AppTableCell align="right">
				<IconButton onClick={() => DeletePageType ()}><IconDelete/></IconButton>

				<IconButton onClick={() => history.push (kPageTypeEditRoute.replace (':pageTypeId', `${pageType.id}`))}><Edit/></IconButton>
			</AppTableCell>
		</TableRow>
	);
}
