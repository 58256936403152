import { Grid, makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";
import { appUseStyles } from "../../App";

const useStyles = makeStyles ((theme: Theme) => ({
	actionsPanelRight: {
		display: "flex",
		justifyContent: "flex-end",
	},
    gridBottom: {
        marginBottom: theme.spacing (2),
    },
    gridTop: {
        marginTop: theme.spacing (2),
    },
}));

export interface Props {
    leftAction?: JSX.Element;
	rightAction?: JSX.Element;
    marginTop?: boolean;
    marginBottom?: boolean;
}

/**
 * Render the component into html.
 */
function ActionsPanel (props: Props) {
    const {leftAction, rightAction, marginTop, marginBottom} = props;

    const classes = useStyles ();
    const appClasses = appUseStyles ();

    const renderLeftActions = leftAction ? (
        <Grid item={true} className={clsx ([appClasses.displayFlex, appClasses.flexGrow])}>
            {leftAction}
        </Grid>
    ) : null;

    const renderRightAction = rightAction ? (
        <Grid item={true} className={clsx ([appClasses.displayFlex, classes.actionsPanelRight, appClasses.flexGrow])}>
            {rightAction}
        </Grid>
    ) : null;

    const className: string [] = [];
    if (marginBottom) {
        className.push (classes.gridBottom);
    }
    if (marginTop) {
        className.push (classes.gridTop);
    }

    return (
        <Grid className={clsx (className)} container={true} spacing={2}>
            {renderLeftActions}

            {renderRightAction}
        </Grid>
    );
}

export default ActionsPanel;
