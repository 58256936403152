import { Button, makeStyles, Theme, Typography } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../App";
import { DeleteAllUserVerifications, UpdateUserVerifications } from "../model/providers/UserVerificationProvider";
import AppPaper from "./components/AppPaper";
import ScreenContent from "./components/ScreenContent";
import { kPageTypeAddRoute } from "./pageTypes/PageTypeEditScreen";
import { kPageTypesListRoute } from "./pageTypes/PageTypesListScreen";

export const kSettingsRoute = '/settings';

const useStyles = makeStyles ((theme: Theme) => ({
    action: {
        marginRight: theme.spacing (2)
    },
    msgCSV: {
        marginTop: theme.spacing (2)
    }
}));

/**
 * Component for Settings screen.
 */
export default function SettingsScreen () {
    const appContext = useContext (AppContext);
    const {pageTypes, userVerifications, setModals} = appContext;

	const history = useHistory ();

    const classes = useStyles ();

    const [errorMsgCSV, setErrorMsgCSV] = useState<string> ();
    const [successCSV, setSuccessCSV] = useState<boolean> ();

    useEffect (() => {
        appContext.SetTitle ('Settings');
    }, []);

    useEffect (() => {
        UpdateUserVerifications (appContext);
    }, []);

    const pageTypesJSX = pageTypes.data && pageTypes.data.length > 0 ? (
        <Typography paragraph={true}>
            Configured page types: {pageTypes.data.map (pageType => pageType.title).join (', ')}
        </Typography>
    ) : (
        <Typography paragraph={true}>
            There are no configured page types.
        </Typography>
    );

    const userVerificationsJSX = userVerifications.data && userVerifications.data.length > 0 ? (
        <Typography paragraph={true}>
            There are {userVerifications.data.length} User Verifications configured.
        </Typography>
    ) : (
        <Typography paragraph={true}>
            There are no configured User Verifications.
        </Typography>
    );

    const deleteAllUserVerificationsJSX = userVerifications.data && userVerifications.data.length ? (
        <Button className={classes.action} variant="contained" color="secondary" onClick={() => DeleteAllUserVerifications (appContext)}>Delete All</Button>
    ) : null;

    /**
     * Use modal to upload CSV for UserVerifications.
     */
    const UserVerificationCSV = () => {
        setErrorMsgCSV (undefined);
        setSuccessCSV (undefined);

        setModals ({
            fileUpload: {
                open: true,
                text: 'Upload CSV file with list of emails and verification codes.',
                onSubmit: (success: boolean) => {
                    appContext.setModals (prev => {
                        return {
                            ...prev,
                            fileUpload: {
                                ...prev.fileUpload!,
                                open: false,
                            },
                        };
                    });

                    if (success) {
                        setTimeout (() => {
                            setErrorMsgCSV ('CSV processed successfully');
                            setSuccessCSV (true);

                            UpdateUserVerifications (appContext);
                        }, 200);
                    } else {
                        setTimeout (() => {
                            setErrorMsgCSV ('CSV processing failed, try different csv file');
                        }, 200);
                    }

                    setTimeout (() => {
                        setErrorMsgCSV (undefined);
                        setSuccessCSV (undefined);
                    }, 2200);
                },
            }
        });
    };

    const errorJSXCSV = errorMsgCSV ? (
        <Typography className={classes.msgCSV} color={successCSV ? 'primary' : 'error'} align="left">
            {errorMsgCSV}
        </Typography>
    ) : null;

    return (
        <ScreenContent>
            <AppPaper>
                {pageTypesJSX}

                <Button className={classes.action} variant="outlined" onClick={() => history.push (kPageTypesListRoute)}>Configure</Button>

                <Button variant="contained" color="primary" onClick={() => history.push (kPageTypeAddRoute)}>Add Page Type</Button>
            </AppPaper>

            {/*<AppPaper>*/}
            {/*    {userVerificationsJSX}*/}

            {/*    <Button className={classes.action} variant="contained" color="primary" onClick={UserVerificationCSV}>Upload CSV</Button>*/}

            {/*    {deleteAllUserVerificationsJSX}*/}

            {/*    {errorJSXCSV}*/}
            {/*</AppPaper>*/}
        </ScreenContent>
    );
}
