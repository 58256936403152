import { Typography } from "@material-ui/core";
import { useContext, useEffect } from "react";
import { AppContext } from "../App";
import AppPaper from "./components/AppPaper";
import ScreenContent from "./components/ScreenContent";

export const kDashboardRoute = '/';

/**
 * Component representing Dashboard screen.
 */
function DashboardScreen () {
	const appContext = useContext (AppContext);

    useEffect (() => {
        appContext.SetTitle ('Dashboard');
    }, []);

	return (
		<ScreenContent>
			<AppPaper>
				<Typography>
					Welcome to the Infokanal BE management system.
				</Typography>
			</AppPaper>
		</ScreenContent>
	);
}

export default DashboardScreen;
