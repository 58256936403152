export const kDevA = {
    apiKey: "AIzaSyCNo2-Cx-2BAkkune-rgR6-yeY6HPcsGw8",
    authDomain: "infokanal-72f29.firebaseapp.com",
    projectId: "infokanal-72f29",
    storageBucket: "infokanal-72f29.appspot.com",
    messagingSenderId: "303682437868",
    appId: "1:303682437868:web:c03ef5794a9cf63c785bc9"
};

export const kDevB = {
    apiKey: "AIzaSyBqQ87S-vmdhQa-vW3LBYQaPwI_rVn82_k",
    authDomain: "infokanalb-9010b.firebaseapp.com",
    projectId: "infokanalb-9010b",
    storageBucket: "infokanalb-9010b.appspot.com",
    messagingSenderId: "679168471396",
    appId: "1:679168471396:web:031df0c44850486c1f8a71",
    measurementId: "G-6R3W1Y6D75"
};

export const kSchleithde = {
    apiKey: "AIzaSyB0n0MoyKDY5wlLy6jfWlA6LTsc5ULdiRA",
    authDomain: "schleith-app.firebaseapp.com",
    projectId: "schleith-app",
    storageBucket: "schleith-app.appspot.com",
    messagingSenderId: "699047519394",
    appId: "1:699047519394:web:efc850d81ad6a1d43063fb",
    measurementId: "G-6V8TPPE95Q"
};
