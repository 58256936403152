import {Link, useHistory, useParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../App";
import Form, {InputsData, InputType, ValidateForm} from "../components/Form";
import {Get, Post} from "../../Api";
import {Button, Typography} from "@material-ui/core";
import ActionsPanel from "../components/ActionsPanel";
import ScreenContent from "../components/ScreenContent";
import {ChevronLeft} from "@material-ui/icons";
import AppPaper from "../components/AppPaper";
import IResponseUserVerification from "../../model/ResponseUserVerification";
import IRequestUserVerification from "../../model/RequestUserVerification";
import {kVerificationCodeListRoute} from "./VerificationCodesListScreen";

export const kVerificationCodeAddRoute = '/settings/verification-code/add';
export const kVerificationCodeEditRoute = '/settings/verification-code/:verificationCodeId/edit';

export interface IParams {
    verificationCodeId?: string;
}

/**
 * Component for VerificationCode add and edit screen.
 */
export default function VerificationCodeEditScreen() {
    const {verificationCodeId} = useParams<IParams>();

    const appContext = useContext(AppContext);

    const history = useHistory();

    const [existingVerificationCode, setExistingVerificationCode] = useState<IResponseUserVerification>();
    const [errorMsg, setErrorMsg] = useState<string>();
    const [success, setSuccess] = useState<boolean>();

    useEffect(() => {
        appContext.SetTitle(verificationCodeId ? 'Edit Verification code' : 'Add Verification code');
    }, []);

    const [inputs, setInputs] = useState<InputsData>({
        verificationCode: {
            type: InputType.Text,
            label: 'Verification code',
            value: '',
            required: true,
        },
        username: {
            type: InputType.Text,
            label: 'Employee name',
            value: '',
            required: true,
        },
        email: {
            type: InputType.Text,
            label: 'Email (empty until an employee registers)',
            value: '',
            required: false,
        },
    });

    useEffect(() => {
        if (verificationCodeId) {
            const cancelTokenSource = Get(
                `/api/user-verification/${verificationCodeId}`,
                appContext,
                result => {
                    if (!result.error) {
                        const existing = result.result as IResponseUserVerification;

                        let newInputs = {...inputs};

                        newInputs.verificationCode.value = existing.code;
                        newInputs.username.value = existing.username;
                        newInputs.email.value = existing.email;

                        setInputs(newInputs);

                        setExistingVerificationCode(existing);
                    }
                },
            );

            return () => {
                cancelTokenSource.cancel();
            };
        }
    }, []);

    /**
     * Save UserVerification to BE API.
     */
    const Save = () => {
        setErrorMsg(undefined);
        setSuccess(undefined);

        if (ValidateForm(inputs, (inputs: InputsData) => setInputs(inputs))) {
            const userVerification: IRequestUserVerification = {
                id: existingVerificationCode ? existingVerificationCode.id : undefined,
                verificationCodeInput: {
                    code: inputs.verificationCode.value,
                    username: inputs.username.value,
                    createdAt: existingVerificationCode ? existingVerificationCode.createdAt : undefined,
                },
                userInput: existingVerificationCode?.email != null || (inputs.email.value != null && inputs.email.value != "") ? {
                    email: inputs.email.value as string,
                    verifiedAt: existingVerificationCode?.verifiedAt
                } : undefined
            };

            try {
                Post(
                    '/api/user-verification/verification-code',
                    userVerification,
                    appContext,
                    result => {
                        if (result.result?.success) {
                            setErrorMsg('User verification saved');
                            setSuccess(true);

                            setTimeout(() => {
                                history.push(kVerificationCodeListRoute);
                            }, 1000);
                        } else {
                            setErrorMsg('API error. Verification code might exist');
                        }
                    }
                );
            } catch (e) {
                console.error(e);

                setErrorMsg('UnKnown Error');
            }
        }
    };

    const errorJsx = errorMsg ? (
        <Typography color={success ? 'primary' : 'error'} align="right" paragraph={true}>
            {errorMsg}
        </Typography>
    ) : (
        <></>
    );

    const content = verificationCodeId && !existingVerificationCode ? (
        <Typography color="error">
            Verification code does not exist
        </Typography>
    ) : (
        <>
            <Form id="user-verification-form" inputs={inputs} SetInputs={(inputs: InputsData) => setInputs(inputs)}/>

            {errorJsx}

            <ActionsPanel rightAction={
                <Button variant="contained" color="primary"
                        onClick={() => Save()}>{verificationCodeId ? 'Update' : 'Add'}</Button>
            }/>
        </>
    );

    return (
        <ScreenContent>
            <ActionsPanel marginBottom={true} leftAction={
                <Button variant="outlined" color="secondary" component={Link}
                        to={kVerificationCodeListRoute}><ChevronLeft/></Button>
            }/>

            <AppPaper>
                {content}
            </AppPaper>
        </ScreenContent>
    );
}
