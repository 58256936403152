import axios, { AxiosRequestConfig, CancelTokenSource } from "axios";
import { IAppContext } from "./App";

export interface IApiResponse {
	result?: any;
	error?: boolean;
}

/**
 * Send GET request to the API.
 */
export function Get (endpoint: string, appContext: IAppContext, callback?: (result: IApiResponse) => void): CancelTokenSource {
	const {flavor, authUser} = appContext;

	const url = `${flavor!.api}${endpoint}`;
	const params: AxiosRequestConfig = {
		auth: {
			username: `${flavor!.name};${authUser!.email}`,
			password: authUser!.idToken
		}
	};

	const cancelTokenSource = axios.CancelToken.source ();
	params.cancelToken = cancelTokenSource.token;

	params.headers = {
		'flavor': flavor!.name
	};

	axios.get (url, params)
		.then (response => {
			if (callback) {
				callback ({
					result: response.data
				});
			}
		})
		.catch (error => {
			console.error (error);

			if (callback) {
				callback ({
					error: true
				});
			}
		});

	return cancelTokenSource;
}

/**
 * API GET request using Promise.
 */
export function GetPromise (endpoint: string, appContext: IAppContext): Promise<IApiResponse> {
	return new Promise<IApiResponse> ((resolve, reject) => {
		const {flavor, authUser} = appContext;

		const url = `${flavor!.api}${endpoint}`;
		const params: AxiosRequestConfig = {
			auth: {
				username: `${flavor!.name};${authUser!.email}`,
				password: authUser!.idToken
			}
		};

		params.headers = {
			'flavor': flavor!.name
		};

		axios.get (url, params)
			.then (response => {
				resolve ({
					result: response.data
				});
			})
			.catch (error => {
				console.error (error);

				resolve ({
					error: true
				});
			});
	});
}

/**
 * Send POST request to the API.
 */
export function Post (endpoint: string, data: object, appContext: IAppContext, callback?: (result: IApiResponse) => void): CancelTokenSource {
	const {flavor, authUser} = appContext;

	const url = `${flavor!.api}${endpoint}`;
	const params: AxiosRequestConfig = {
		auth: {
			username: `${flavor!.name};${authUser!.email}`,
			password: authUser!.idToken
		}
	};

	const cancelTokenSource = axios.CancelToken.source ();
	params.cancelToken = cancelTokenSource.token;

	params.headers = {
		'flavor': flavor!.name,
	};

	axios.post (url, data, params)
		.then (response => {
			if (callback) {
				callback ({
					result: response.data
				})
			}
		})
		.catch (error => {
			console.error (error);

			if (callback) {
				callback ({
					error: true
				});
			}
		});

	return cancelTokenSource;
}

/**
 * Send DELETE request to API.
 */
export function Delete (endpoint: string, appContext: IAppContext, callback?: (result: IApiResponse) => void) {
	const {flavor, authUser} = appContext;

	const url = `${flavor!.api}${endpoint}`;
	const params: AxiosRequestConfig = {
		auth: {
			username: `${flavor!.name};${authUser!.email}`,
			password: authUser!.idToken
		}
	};

	params.headers = {
		'flavor': flavor!.name,
	};

	axios.delete (url, params)
		.then (response => {
			if (callback) {
				callback ({
					result: response.data
				});
			}
		})
		.catch (error => {
			console.error (error);

			if (callback) {
				callback ({
					error: true
				});
			}
		});
}

/**
 * File upload as POST request to the API.
 */
export function Upload (data: FormData, appContext: IAppContext, callback?: (result: IApiResponse) => void, onProgressUpdate?: (progress: number) => void, urlEndpoint?: string): CancelTokenSource {
	const {flavor, authUser} = appContext;

	const url = `${flavor!.api}${urlEndpoint || '/api/file/upload'}`;
	const params: AxiosRequestConfig = {
		auth: {
			username: `${flavor!.name};${authUser!.email}`,
			password: authUser!.idToken
		}
	};

	const cancelTokenSource = axios.CancelToken.source ();
	params.cancelToken = cancelTokenSource.token;

	params.headers = {
		'flavor': flavor!.name,
		'Content-Type': 'multipart/form-data'
	};

	params.onUploadProgress = (progressEvent: {loaded: number, total: number}) => {
		if (onProgressUpdate) {
			const progress = progressEvent.loaded / progressEvent.total;

			onProgressUpdate (progress);
		}
	};

	axios.post (url, data, params)
		.then (response => {
			if (callback) {
				callback ({
					result: response.data
				});
			}
		})
		.catch (error => {
			console.error (error);

			if (callback) {
				callback ({
					error: true
				});
			}
		});

	return cancelTokenSource;
}
