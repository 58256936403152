import { CancelTokenSource } from "axios";
import { Delete, Get, IApiResponse } from "../../Api";
import { IAppContext } from "../../App";

/**
 * Load all UserVerifications from BE API.
 */
export function UpdateUserVerifications (appContext: IAppContext): CancelTokenSource {
    return Get (
		'/api/user-verification',
		appContext,
		(result: IApiResponse) => {
			const newUserVerifications = {...appContext.userVerifications};

			if (result.error) {
				console.error (result.error);

				newUserVerifications.data = [];
			} else {
				newUserVerifications.data = result.result.userVerificationList;
			}

			appContext.setUserVerifications (newUserVerifications);
		},
	);
}

/**
 * Delete all UserVerifications and update list.
 */
export function DeleteAllUserVerifications (appContext: IAppContext) {
    appContext.setModals ({
        confirm: {
            open: true,
            text: 'Do you want to delete all User Verifications?',
            onConfirm: (confirmed: boolean) => {
                if (confirmed) {
                    Delete (
                        `/api/user-verification/all`,
                        appContext,
                        result => {
                            if (result.error) {
                                console.error (result.error);
                            }

                            UpdateUserVerifications (appContext);
                        }
                    )
                }
            },
        },
    });
}
