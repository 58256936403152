import {Route, Switch} from "react-router-dom";
import ArticleEditScreen, {kArticleAddRoute, kArticleEditRoute} from "./ui/articles/ArticleEditScreen";
import ArticlesListScreen, {kArticlesListRoute} from "./ui/articles/ArticlesListScreen";
import ProtectedRoute from "./ui/components/ProtectedRoute";
import DashboardScreen, {kDashboardRoute} from "./ui/DashboardScreen";
import FCMFormScreen, {kFCMFormRoute} from "./ui/FCMFormScreen";
import LoginScreen, {kLoginRoute} from "./ui/LoginScreen";
import LogoutScreen, {kLogoutRoute} from "./ui/LogoutScreen";
import NoMatchScreen from "./ui/NoMatchScreen";
import PageEditScreen, {kPageAddRoute, kPageEditRoute} from "./ui/pages/PageEditScreen";
import PageListScreen, {kPageListRoute} from "./ui/pages/PageListScreen";
import PageTypeEditScreen, {kPageTypeAddRoute, kPageTypeEditRoute} from "./ui/pageTypes/PageTypeEditScreen";
import PageTypesListScreen, {kPageTypesListRoute} from "./ui/pageTypes/PageTypesListScreen";
import SettingsScreen, {kSettingsRoute} from "./ui/SettingsScreen";
import VerificationCodesListScreen, {kVerificationCodeListRoute} from "./ui/verificationCodes/VerificationCodesListScreen";
import VerificationCodeEditScreen, {
        kVerificationCodeAddRoute,
        kVerificationCodeEditRoute
} from "./ui/verificationCodes/VerificationCodeEditScreen";
import ConstructionSitesListScreen, {
        kConstructionSitesListRoute
} from "./ui/constructionSites/ConstructionSitesListScreen";

/**
 * Router component history based routing.
 */
function Router () {
    return (
        <Switch>
            <Route path={kLoginRoute} component={LoginScreen}/>
            <Route path={kLogoutRoute} component={LogoutScreen}/>

            <ProtectedRoute exact={true} path={kDashboardRoute} redirectTo={kLoginRoute} component={DashboardScreen}/>

            <ProtectedRoute exact={true} path={kPageAddRoute} redirectTo={kLoginRoute} component={PageEditScreen}/>
            <ProtectedRoute exact={true} path={kPageEditRoute} redirectTo={kLoginRoute} component={PageEditScreen}/>
            <ProtectedRoute exact={true} path={kPageListRoute} redirectTo={kLoginRoute} component={PageListScreen}/>

            <ProtectedRoute path={kArticleAddRoute} redirectTo={kLoginRoute} component={ArticleEditScreen}/>
            <ProtectedRoute path={kArticleEditRoute} redirectTo={kLoginRoute} component={ArticleEditScreen}/>
            <ProtectedRoute path={kArticlesListRoute} redirectTo={kLoginRoute} component={ArticlesListScreen}/>

            <ProtectedRoute path={kConstructionSitesListRoute} redirectTo={kLoginRoute} component={ConstructionSitesListScreen}/>

            <ProtectedRoute path={kPageTypeAddRoute} redirectTo={kLoginRoute} component={PageTypeEditScreen}/>
            <ProtectedRoute path={kPageTypeEditRoute} redirectTo={kLoginRoute} component={PageTypeEditScreen}/>
            <ProtectedRoute path={kPageTypesListRoute} redirectTo={kLoginRoute} component={PageTypesListScreen}/>
            <ProtectedRoute path={kVerificationCodeAddRoute} redirectTo={kLoginRoute} component={VerificationCodeEditScreen}/>
            <ProtectedRoute path={kVerificationCodeEditRoute} redirectTo={kLoginRoute} component={VerificationCodeEditScreen}/>
            <ProtectedRoute path={kVerificationCodeListRoute} redirectTo={kLoginRoute} component={VerificationCodesListScreen}/>
            <ProtectedRoute path={kSettingsRoute} redirectTo={kLoginRoute} component={SettingsScreen}/>

            <ProtectedRoute path={kFCMFormRoute} redirectTo={kLoginRoute} component={FCMFormScreen}/>

            <Route component={NoMatchScreen}/>
        </Switch>
    );
}

export default Router;
