import { CancelTokenSource } from "axios";
import { Get, IApiResponse } from "../../Api";
import { IAppContext } from "../../App";

/**
 * Load PageTypes from BE API.
 */
export function UpdatePageTypes (appContext: IAppContext): CancelTokenSource {
    return Get (
		'/api/page-type',
		appContext,
		(result: IApiResponse) => {
			const newPageTypes = {...appContext.pageTypes};

			if (result.error) {
				console.error (result.error);

				newPageTypes.data = [];
			} else {
				newPageTypes.data = result.result.pageTypeList;
			}

			appContext.setPageTypes (newPageTypes);
		},
	);
}
