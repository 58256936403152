/* eslint-disable no-whitespace-before-property */
import { Button, makeStyles, Theme } from "@material-ui/core";
import { CloudUpload } from "@material-ui/icons";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import Cog from "../../../icons/Cog";
import Document from "../../../icons/Document";
import { formUseStyles } from "../Form";
import {FileInputFile, FileInputProps} from "./InputProps";

const useStyles = makeStyles ((theme: Theme) => ({
	error: {
		margin: 0,
		marginLeft: 14,
		marginRight: 14,
		color: '#f44336',
		fontSize: '0.75rem',
		fontWeight: 400,
	},
	fileInput: {
		display: 'flex',
		flexDirection: 'column',
		overflow: 'hidden',
		alignItems: 'center',
		justifyContent: 'center',
		minHeight: 56,
		marginTop: theme.spacing (2),
		marginBottom: theme.spacing (1),
		border: `1px solid rgba(0, 0, 0, 0.23)`,
		borderRadius: 4,
		color: 'rgba(0, 0, 0, 0.54)',
		"&:hover, &:focus": {
			borderColor: 'rgba(0, 0, 0, 0.87)',
			color: theme.palette.primary.main,
		},
	},
	fileInputError: {
		borderColor: '#f44336',
		"&:hover, &:focus": {
			borderColor: '#f44336',
		},
	},
	fileInputUploading: {
		borderColor: 'rgba(0, 0, 0, 0.87)',
		color: 'white',
		background: theme.palette.primary.main,
		"&:hover, &:focus": {
			color: 'white',
		},
	},
	mainSection: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
		minHeight: 56,
		cursor: 'pointer',
		"&:hover, &:focus": {
			textDecoration: 'none',
			background: 'rgba(0, 0, 0, 0.04)',
		},
	},
	fileInputImages: {
		display: 'flex',
		flexDirection: 'column'
	},
	fileInputImage: {
		height: 112,
		cursor: 'default',
	},
	fileInputContents: {
		verticalAlign: 'middle',
	},
	fileLink: {
		display: 'inline-flex'
	},
	hidden: {
		display: 'none',
	},
	image: {
		height: 56,
	},
	space: {
		display: 'inline-block',
		width: theme.spacing (2),
	},
	spaceText: {
		display: 'inline-block',
		width: theme.spacing (0.5),
	},
	defaultIconStyle: {
		height: '100%',
		color: 'black',
	}
}));

/**
 * Render the component into html.
 */
function FileInput (props: FileInputProps) {
    const {index, className, label, value, UpdateProp, UploadFile, UpdateValue, FilePublicUrl, uploading, multiple, anyFile, hidden, error, errorMessage} = props;

	const classes = useStyles ();

	const formClasses = formUseStyles ();

	const inputRef = useRef<HTMLInputElement> (null);

	const fileIds: string [] = value ? `${value}`.split (',') : [];
	const [fileUrls, setFileUrls] = useState<FileInputFile []> ([]);

	useEffect (() => {
		if (fileIds.length === 0 && fileUrls.length > 0) {
			setFileUrls ([]);
		} else if (fileIds.length > 0 && fileUrls.length === 0) {
			InitFileUrls ();
		}
	}, [value]);

	/**
	 * Used to initialize file urls for existing files.
	 */
	const InitFileUrls = async () => {
		const newFileUrls = [];

		try {
			for (let i = 0; i < fileIds.length; i++) {
				const url = await FilePublicUrl (fileIds [i]);

				newFileUrls.push (url);
			}
		} catch (e) {
			console.error (`FileInput: ${e}`);
		}

		setFileUrls (newFileUrls);
	};

	/**
	 * Decide what to do when clicked.
	 */
	const OnClick = () => {
		ChooseFile ();
	};

	/**
	 * Choose file using input.
	 */
	const ChooseFile = () => {
		if (inputRef.current) {
			inputRef.current.click ();
		}
	};

	/**
	 * File chosen, upload to API.
	 */
	const FileChosen = async (e: React.ChangeEvent<HTMLInputElement>) => {
		if (inputRef.current) {
			const htmlInput: HTMLInputElement = e.target;
			const file: string = htmlInput.value;

			if (file !== '' && htmlInput.files && htmlInput.files.length > 0) {
				UpdateProp (index, 'uploading', true);

				try {
					const newValue = await UploadFile (htmlInput.files);

					const newFileIds = [...fileIds];
					const newFileUrls = [...fileUrls];

					if (!multiple) {
						newFileIds.length = 0;
						newFileUrls.length = 0;
					}

					const newFileUrl = await FilePublicUrl (newValue);

					newFileIds.push (newValue);
					newFileUrls.push (newFileUrl);

					setFileUrls (newFileUrls);
					UpdateValue (index, newFileIds.join (','));
				} catch (e) {
					console.error (e);

					UpdateValue (index, '');
				}

				UpdateProp (index, 'uploading', false);
			}
		}
	};

	const files = fileUrls ? fileUrls.map ((url, urlIndex) => {
		const replace = multiple ? null : (
			<>
				<div className={classes.space}/>

				<Button className={classes.fileInputContents} onClick={() => ChooseFile ()}>Replace</Button>
			</>
		);

		/**
		 * Remove this file.
		 */
		const Remove = () => {
			const newFileIds = [...fileIds];
			newFileIds.splice (urlIndex, 1);

			const newFileUrls = [...fileUrls];
			newFileUrls.splice (urlIndex, 1);

			setFileUrls (newFileUrls);
			UpdateValue (index, newFileIds.join (','));
		};

        const imgJsx = url && ['png','jpg','jpeg','tif','tiff','bmp','eps'].includes(url.name.split('.').pop()!.toLowerCase()) ? (
			<a className={clsx (classes.fileInputContents, classes.fileLink)} href={url.url} rel="noreferrer" target="_blank">
				<img className={classes.image} src={url.url} alt={value}/>
			</a>
        ) : (
        <a className={clsx (classes.fileInputContents, classes.fileLink)} href={url.url} rel="noreferrer" target="_blank">
			<Document className={classes.defaultIconStyle} small={true}/>
        </a>
        );

		return (
			<div key={url.url}>
				{imgJsx}

				<div className={classes.space}/>

				<Button className={classes.fileInputContents} onClick={Remove}>Remove</Button>

				{replace}
			</div>
		);
	}) : [];

	const filesContainer = files.length > 0 ? (
		<div className={classes.fileInputImages}>
			{files}
		</div>
	) : null;

	const accept = anyFile ? undefined : "image/*";

	let uploadSection = fileIds.length === 0 || multiple ? (
		<div className={clsx (classes.fileInput, (uploading ? classes.fileInputUploading : undefined), (error ? classes.fileInputError : undefined))}>
			{filesContainer}

			<div className={classes.mainSection} onClick={() => OnClick ()}>
				<span className={classes.fileInputContents}>{label}</span><div className={classes.spaceText}/><CloudUpload className={classes.fileInputContents}/>

				<input ref={inputRef} className={classes.hidden} type="file" accept={accept} onChange={FileChosen}/>
			</div>
		</div>
	) : null;

	if (uploading) {
		uploadSection = (
			<div className={clsx (classes.fileInput, (uploading ? classes.fileInputUploading : undefined), (error ? classes.fileInputError : undefined))}>
				{filesContainer}

				<div className={classes.mainSection}>
					<Cog spin={true} small={true}/>

					<input ref={inputRef} className={classes.hidden} type="file" accept={accept} onChange={FileChosen}/>
				</div>
			</div>
		);
	}

	uploadSection = !uploadSection && filesContainer ? (
		<div className={clsx (classes.fileInput, (uploading ? classes.fileInputUploading : undefined), (error ? classes.fileInputError : undefined))}>
			{filesContainer}
		</div>
	) : uploadSection;

	const errorJSX = error ? (
		<p className={classes.error}>{errorMessage}</p>
	) : undefined;

	return (
		<div className={clsx ('Form_FileInput', className, (hidden ? formClasses.hidden : undefined))}>
			{uploadSection}

			{errorJSX}
		</div>
	);
}

export default FileInput;
